import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";

class UpdateSuperCategory extends Component {
  state = {
    name: "",
    id: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    image: "",
    modalVisible: false,
    // image preview
    imageFile: "",
    photoUrl: "",
    uploadImgVisi: true,
  };
  componentDidMount() {
    let data = this.props.location.state.data;
    console.log(this.props, "props");
    this.setState({
      name: data.title,
      id: data._id,
      image: data.image ? data.image : "",
    });
  }

  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  // image preview handle
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();

    fd.append("title", this.state.name);
    fd.append("image", this.state.image);
    axios
      .put(Constants.getUrls.super_categories + "/" + this.state.id, fd)
      .then((resp) => {
        fd.delete("image");
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/supercategories"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Super Category Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This SuperCategory Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Super Category</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              value={this.state.name}
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Row>
                            <Col className="pr-1" md="6">
                              <label>Image (500*500)</label>
                              <br />
                              <input
                                type="file"
                                onChange={(e) => {
                                  this.handleFile(e);
                                  this.handlePreview(e);
                                  this.setState({
                                    uploadImgVisi: !this.state.uploadImgVisi,
                                  });
                                }}
                                // required={true}
                                name="image"
                              />
                            </Col>
                            {/* <Col className="pr-1" md="6">
                              <label>Image preview</label>
                              <br />
                              <img
                                width={100}
                                src={this.state.photoUrl}
                                alt=""
                              />
                            </Col> */}
                          </Row>
                        </Col>

                        {this.state.uploadImgVisi ? (
                          <Col className="pr-1" md="6">
                            <label>Uploaded Image</label>
                            <br />
                            <img
                              width={100}
                              src={Constants.imgUrl + this.state.image}
                              alt="image"
                            />
                          </Col>
                        ) : (
                          <Col className="pr-1" md="6">
                            <label>Image preview</label>
                            <br />
                            <img width={100} src={this.state.photoUrl} alt="" />
                          </Col>
                        )}
                      </Row>
                      <Row className="mt-5">
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="info"
                            type="submit"
                          >
                            {this.state.loading ? "Updating..." : "Update"}
                          </Button>
                          <Link to="/admin/supercategories">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateSuperCategory;
