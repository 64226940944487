import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function NotificationOrganelle({ className, keyProp, message, style }) {
  return (
    <>
      <div
        key={keyProp}
        id={`notification-organelle-${keyProp}`}
        style={style}
        className={`m-2 notification-organelle  ${className}`}
      >
        <div className={`container p-2 d-flex align-items-center `}>
          <FontAwesomeIcon icon={faBell} className="p-2 mr-3" />
          <p className=" m-0">{message}</p>
        </div>
      </div>
    </>
  );
}

export default NotificationOrganelle;
