/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Index from "views/Index.js";
// import Profile from "views/examples/Profile.js";
// import Maps from "views/examples/Maps.js";
// import Register from "views/examples/Register.js";
// import Login from "views/examples/Login.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";
import Categories from "views/Categories";
import SubCategories from "views/SubCategories";
import PrintTypes from "views/PrintTypes";
import Vendors from "views/Vendors";
import Products from "views/Products";
// import DeliveryModes from "views/DeliveryModes";
import DeliveryPartners from "views/DeliveryPartners";
// import Logistics from "views/Logistics";
import Coupons from "views/Coupons";
import Advertisements from "views/Advertisements";
import Pincodes from "views/Pincodes";
import TaxTypes from "views/TaxTypes";
// import HomePages from "views/HomePages";
import HomeSliders from "views/HomeSliders";
import BestSellers from "views/BestSellers";
import ProductsFor from "views/ProductsFor";
import VendorCommission from "views/VendorCommission";
import Orders from "views/Orders";
import Rating from "views/Rating";
import MobileHomeSliders from "views/MobileHomeSliders";
import BulkOrders from "views/BulkOrders";
import BulkOrderRequests from "views/BulkOrderRequests";
import VendorRequests from "views/VendorRequests";
import Users from "views/Users";
import Variants from "views/Variants";
import UpdateVariant from "UpdateViews/UpdateVariant";
import AddVariants from "AddViews/AddVariants";
import Super_Categories from "views/SuperCategories";
import Reasons from "views/Reasons";
import DashBoard from "views/DashBoard";
import OurInvestors from "views/OurInvestors";
import Size from "views/Size";
import Color from "views/Color";
import PaymentImageSliders from "views/PaymentImageSliders";
import BulkSMS from "views/BulkSMS";
import AddSettings from "views/AddSettings";
import Brand from "views/Brand";
import Inventory from "views/Inventory";
import Password from "views/Password";
import SubAdmin from "views/SubAdmin";

// import ExchangeProduct from "views/ExchangeProduct";
//
// import Icons from "views/examples/Icons";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-brown",
  //   component: Icons,
  //   layout: "/admin"
  // },

  {
    id: "0",
    path: "/subAdmin",
    name: "Sub Admin",
    icon: "fa fa-chess-king text-brown",
    component: SubAdmin,
    layout: "/admin",
  },
  {
    id: "1",
    path: "/index",
    name: "DashBoard",
    icon: "fa fa-chess-king text-brown",
    component: DashBoard,
    layout: "/admin",
  },

  // {
  //   id: "2",
  //   path: "/menu",
  //   name: "Menus",
  //   icon: "fa fa-bars text-brown",
  //   component: Menu,
  //   layout: "/admin",
  // },
  // {
  //   id: "3",
  //   path: "/subMenu",
  //   name: "Sub Menus",
  //   icon: "fa fa-bars text-brown",
  //   component: SubMenu,
  //   layout: "/admin",
  // },
  // {
  //   id: "4",
  //   path: "/role",
  //   name: "Roles",
  //   icon: "fa fa-user-tie text-brown",
  //   component: Role,
  //   layout: "/admin",
  // },
  // {
  //   id: "5",
  //   path: "/collaborators",
  //   name: "Collaborators",
  //   icon: "fa fa-address-card text-brown",
  //   component: Collaborators,
  //   layout: "/admin",
  // },
  {
    id: "6",
    path: "/orders",
    name: "Orders",
    icon: "fa fa-cart-plus text-brown",
    component: Orders,
    layout: "/admin",
  },
  {
    id: "7",
    path: "/rating",
    name: "Rating",
    icon: "fa fa-star text-brown",
    component: Rating,
    layout: "/admin",
  },
  {
    id: "8",
    path: "/users",
    name: "Users",
    icon: "fa fa-users text-brown",
    component: Users,
    layout: "/admin",
  },
  // {
  //   path: "/bulkorderrequests",
  //   name: "Bulk Order Requests",
  //   icon: "ni ni-box-2 text-brown",
  //   component: BulkOrderRequests,
  //   layout: "/admin",
  // },

  // {
  //   path: "/bulkorders",
  //   name: "Bulk Proucts",
  //   icon: "ni ni-shop text-brown",
  //   component: BulkOrders,
  //   layout: "/admin",
  // },
  {
    id: "9",
    path: "/supercategories",
    name: "Super Categories",
    icon: "ni ni-bullet-list-67 text-brown",
    component: Super_Categories,
    layout: "/admin",
  },
  {
    id: "10",
    path: "/categories",
    name: "Categories",
    icon: "ni ni-bullet-list-67 text-brown",
    component: Categories,
    layout: "/admin",
  },
  {
    id: "11",
    path: "/subcategories",
    name: "Sub Categories",
    icon: "ni ni-bullet-list-67 text-brown",
    component: SubCategories,
    layout: "/admin",
  },

  {
    id: "12",
    path: "/brand",
    name: "Brand",
    icon: "ni ni-bullet-list-67 text-brown",
    component: Brand,
    layout: "/admin",
  },
  {
    id: "13",
    path: "/productsfor",
    name: "Tribes",
    icon: "ni ni-bullet-list-67 text-brown",
    component: ProductsFor,
    layout: "/admin",
  },
  {
    id: "14",
    path: "/size",
    name: "Size",
    icon: "fa fa-font text-brown",
    component: Size,
    layout: "/admin",
  },
  {
    id: "15",
    path: "/color",
    name: "Color",
    icon: "ni ni-ruler-pencil text-brown",
    component: Color,
    layout: "/admin",
  },
  {
    id: "16",
    path: "/products",
    name: "Products",
    icon: "fa fa-gifts text-brown",
    component: Products,
    layout: "/admin",
  },
  // {
  //   path: "/variant/inventory",
  //   name: "Inventory",
  //   icon: "fa fa-gifts text-brown",
  //   component: Inventory,
  //   layout: "/admin",
  // },
  {
    id: "17",
    path: "/taxtypes",
    name: "Tax Types",
    icon: "ni ni-sound-wave text-brown",
    component: TaxTypes,
    layout: "/admin",
  },
  {
    id: "18",
    path: "/couponcodes",
    name: "Coupons",
    icon: "ni ni-tag text-brown",
    component: Coupons,
    layout: "/admin",
  },
  {
    id: "19",
    path: "/homesliders",
    name: "Home Sliders",
    icon: "ni ni-map-big text-brown",
    component: HomeSliders,
    layout: "/admin",
  },
  {
    id: "20",
    path: "/mobilehomesliders",
    name: "Mobile Home Sliders",
    icon: "ni ni-map-big text-brown",
    component: MobileHomeSliders,
    layout: "/admin",
  },
  {
    id: "21",
    path: "/bestsellers",
    name: "Best Sellers",
    icon: "ni ni-trophy text-brown",
    component: BestSellers,
    layout: "/admin",
  },

  // {
  //   path: "/printtypes",
  //   name: "Print Types",
  //   icon: "ni ni-album-2 text-brown",
  //   component: PrintTypes,
  //   layout: "/admin",
  // },
  // {
  //   path: "/vendors",
  //   name: "Vendors",
  //   icon: "ni ni-single-02 text-brown",
  //   component: Vendors,
  //   layout: "/admin",
  // },
  // {
  //   path: "/vendorcommissions",
  //   name: "Vendor Reports",
  //   icon: "ni ni-ruler-pencil text-brown",
  //   component: VendorCommission,
  //   layout: "/admin",
  // },
  // {
  //   path: "/vendorrequests",
  //   name: "Vendor Requests",
  //   icon: "ni ni-ruler-pencil text-brown",
  //   component: VendorRequests,
  //   layout: "/admin",
  // },
  // {
  //   path: "/deliverymodes",
  //   name: "Delivery Modes",
  //   icon: "ni ni-bus-front-12 text-brown",
  //   component: DeliveryModes,
  //   layout: "/admin",
  // },
  {
    id: "22",
    path: "/deliverypartners",
    name: "Delivery Partners",
    // icon: "ni ni-bus-front-12 text-brown",
    icon: "ni ni-delivery-fast text-brown",
    component: DeliveryPartners,
    layout: "/admin",
  },

  // {
  //   path: "/ads",
  //   name: "Advertisements",
  //   icon: "ni ni-tv-2 text-brown",
  //   component: Advertisements,
  //   layout: "/admin",
  // },
  {
    id: "23",
    path: "/pincodes",
    name: "Pincodes",
    icon: "ni ni-square-pin text-brown",
    component: Pincodes,
    layout: "/admin",
  },

  // {
  //   path: "/logistics",
  //   name: "Logistics",
  //   icon: "ni ni-delivery-fast text-brown",
  //   component: Logistics,
  //   layout: "/admin",
  // },
  // {
  //   path: "/homepages",
  //   name: "Home Pages",
  //   icon: "ni ni-book-bookmark text-brown",
  //   component: HomePages,
  //   layout: "/admin",
  // },

  {
    id: "24",
    path: "/reasons",
    name: " Return/Exch Reasons",
    icon: "ni ni-satisfied text-brown",
    component: Reasons,
    layout: "/admin",
  },
  {
    id: "25",
    path: "/ourInvestors",
    name: "Our Investors",
    icon: "ni ni-single-02 text-brown",
    component: OurInvestors,
    layout: "/admin",
  },

  {
    id: "26",
    path: "/paymentImageSliders",
    // name:"PaymentImageSliders",
    name: "Payment Img Sliders",
    // icon:"ni ni-money-coins text-grey",
    icon: "ni ni-credit-card text-brown",
    component: PaymentImageSliders,
    layout: "/admin",
  },

  // {
  //   path:"/exchangeproduct",
  //   // name:"ExchangeProduct",
  //   name:"Exchange Product",
  //   icon:"ni ni-shop text-brown",
  //   component: ExchangeProduct,
  //   layout: "/admin"
  // },
  {
    id: "27",
    path: "/bulkSMS",
    name: "Bulk SMS",
    icon: "fa fa-sms text-brown",
    component: BulkSMS,
    layout: "/admin",
  },
  {
    id: "28",
    path: "/addSettings",
    name: "Settings",
    icon: "ni ni-settings text-brown",
    component: AddSettings,
    layout: "/admin",
  },
  {
    id: "29",
    path: "/password",
    name: "Password",
    icon: "ni ni-settings text-brown",
    component: Password,
    layout: "/admin",
  },
  // {
  //   id: "30",
  //   path: "/logIn",
  //   name: "Log In",
  //   icon: "ni ni-settings text-brown",
  //   component: LogIn,
  //   layout: "/admin",
  // },
];
export default routes;
