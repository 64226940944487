import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableBodyOrganelle from "components/Organelles/TableBodyOrganelle";
import TableHeaderOrganelle from "components/Organelles/TableHeaderOrganelle";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import Constants from "variables/Constants";
import routes from "../routes";
import NotificationOrganelle from "components/Organelles/NotificationOrganelle";
import { useParams } from "react-router-dom";

function AddRole() {
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);
  const [role, setRole] = useState("");
  const [addNotification, setAddNotification] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [title, setTitle] = useState("");
  const [menuId, setMenuId] = useState([]);
  const [updateNotification, setUpdateNotification] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  let params = useParams();
  let paramsId = params.id;
  console.log(params.id, "params");

  useEffect(() => {
    console.clear();
    fetch_unit_data();
    console.log(routes, "routes");
  }, []);

  useEffect(() => {
    selectAll();
  }, [isChecked]);

  const update_data = () => {
    const payload = {
      name: name,
      mobile_no: mobile,
      menus: data,
    };
    axios
      .put(`${Constants.putUrls.updateSubAdmin}/${paramsId}`, payload)
      .then((response) => {
        console.log(response, "update response");
        if (response.data.status === "success") {
          window.location.href = "/admin/subAdmin";
        } else {
          return;
        }
      });
  };

  const fetch_unit_data = () => {
    axios
      .get(`${Constants.getUrls.singleSubAdmin}/${paramsId}`)
      .then((response) => {
        console.log(response.data.getAllMenus.menus, "single data response");
        setData(response.data.getAllMenus.menus);
        setName(response.data.getAllMenus.user.name);
        setEmail(response.data.getAllMenus.user.email);
        setMobile(response.data.getAllMenus.user.mobile_no);
      });
  };

  // useEffect(() => {}, [previlages]);

  const checkbox_handler = (e) => {
    const isSelected = e.target?.checked;

    if (isSelected) {
      setData([...data, e.target?.value]);
    } else {
      setData(
        data.filter((filteredItem) => {
          return filteredItem !== e.target?.value;
        })
      );
    }
  };

  const selectAll = () => {
    if (isChecked) {
      setData(
        routes.map((item) => {
          return item.id;
        })
      );
    } else {
      setData([]);
    }
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          {updateNotification && (
            <NotificationOrganelle
              style={{
                backgroundColor: "#219d68",
                position: "fixed",
                color: "white",
                right: "10px",
                top: "10px",
              }}
              message={
                <>
                  Menu Updated to{" "}
                  <span style={{ textTransform: "uppercase" }}>{title}</span>,
                  Successfully!
                </>
              }
            />
          )}

          <CardBody>
            <Row className="align-items-center">
              <Col md={12}>
                <Form>
                  <div className="col-12 justify-content-between d-flex flex-column">
                    <div className="row">
                      <div className="col-4">
                        <FormGroup>
                          <label htmlFor=""></label>
                          <Input
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            placeholder="name"
                          ></Input>
                        </FormGroup>
                      </div>
                      <div className="col-4">
                        <FormGroup>
                          <label htmlFor=""></label>
                          <Input
                            value={mobile}
                            onChange={(e) => {
                              setMobile(e.target.value);
                            }}
                            placeholder="mobile"
                          ></Input>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row my-4">
                      <div className="col-3">
                        <input
                          className="mr-2"
                          onClick={() => {
                            setIsChecked(!isChecked);
                          }}
                          type="checkbox"
                        />{" "}
                        <span>Select All</span>
                      </div>
                      {routes.map((route) => {
                        return (
                          <div className="col-3">
                            <input
                              className="mr-2"
                              value={route.id}
                              onClick={(e) => {
                                checkbox_handler(e);
                              }}
                              checked={data.includes(route.id)}
                              type="checkbox"
                            />
                            <span>{route.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Form>
                <button
                  onClick={() => {
                    update_data();
                  }}
                  className="btn btn-success"
                >
                  Update
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddRole;
