import axios from "axios";
import React, { Component } from "react";
import Constants from "variables/Constants";
import { SketchPicker } from "react-color";

import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";
import { faAdd, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class UpdateVariant extends Component {
  state = {
    var_id: "",
    size: "",
    color: "",
    // pack_of: "",
    image: "",
    // design_image: "",
    // price: "",
    mrp_price: "",
    selling_price: "",
    variant_quantity: "",
    // p_id: this.props.location.state.data,
    p_id: this.props.match.params.id,
    page: 1,
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    // data: {},
    data: [],
    allColor: [],
    perPage: "100",
    video: [],
    videoAdded: false,
    // image preview
    imageFile: [],
    photoUrl: "",
    imagePreview: false,
    pop_up: false,
    image: [],
    prev_video: "",
  };
  componentDidMount() {
    this.getAllSize();
    this.getAllColor();
    let data = this.props.location.state.data;
    // console.log(data);
    this.setState(
      {
        // data: this.props.location.state.data,
        var_id: this.props.location.state.data._id,
        id: data._id,
        size: data.size?.size ? data.size._id : "",
        color: data.color?.color ? data.color._id : "",
        // pack_of: data.pack_of,
        // price: data.price,
        mrp_price: data.mrp_price,
        selling_price: data.selling_price,
        variant_quantity: data.variant_quantity,
        image: data.image,
        // design_image: data.design_image,
        p_id: data.product,
        prev_video: data.video_file,
      },
      () => {
        // this.setState({
        //   size: this.state.data.size,
        //   color: this.state.data.color,
        //   pack_of: this.state.data.pack_of,
        //   price: this.state.data.price,
        //   image: this.state.data.image,
        //   design_image: this.state.data.design_image,
        //   p_id: this.state.data.product,
        // });
      }
    );
  }

  delete_image = () => {
    axios
      .delete(
        `${Constants.deleteUrl.variant}/${this.state.var_id}/${this.state.image_index}`
      )
      .then((response) => {
        console.log(response, "response");
        if (response.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
  };

  update_image = (e, index) => {
    // e.preventDefault();
    let fd = new FormData();
    fd.append("image", e.target.files[0]);

    axios
      .put(
        Constants.putUrls.variant + "/" + this.state.var_id + "/" + index,
        fd
      )
      .then((response) => {
        if (response.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
  };
  getAllSize = () => {
    axios
      .get(
        Constants.getUrls.getSize +
          "?limit=1000" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&is_delete=no" +
          "&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp, "romen");
        this.setState({
          data: resp.data._sizes.docs,
        });
      });
  };

  getAllColor = () => {
    axios
      .get(
        Constants.getUrls.getColor +
          "?limit=1000" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&is_delete=no" +
          "&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp, "romen");
        this.setState({
          allColor: resp.data._colors.docs,
        });
      });
  };
  handleVideo = (e) => {
    this.setState({ [e.target.name]: e.target.files[0], videoAdded: true });
  };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let fd = new FormData();
    if (this.state.size.length > 0) {
      fd.append("size", this.state.size);
    }
    if (this.state.color.length > 0) {
      fd.append("color", this.state.color);
    }
    // fd.append("pack_of", this.state.pack_of);
    if (this.state.image.length > 0) {
      for (let i = 0; i < this.state.image.length; i++) {
        fd.append("image", this.state.image[i]);
      }
    }
    if (this.state.videoAdded) {
      fd.append("video", this.state.video);
    }
    // for (let i = 0; i < this.state.image.length; i++) {
    //   fd.append("design_image", this.state.design_image[i]);
    // }
    // fd.append("price", this.state.price);
    fd.append("mrp_price", this.state.mrp_price);
    fd.append("selling_price", this.state.selling_price);
    fd.append("variant_quantity", this.state.variant_quantity);
    if (this.state.p_id) {
      fd.append("p_id", this.state.p_id);
    }

    axios
      .put(Constants.postUrls.postVariants + "/" + this.state.var_id, fd)
      .then((resp) => {
        console.log(resp, "hello");
        fd.delete("image");
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
  };
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files }, () => {});
  };
  // image preview handle
  // handlePreview = (e) => {
  //   this.setState({ imageFile: e.target.files[0] }, () => {
  //     console.log(typeof this.state.imageFile);
  //     if (this.state.imageFile) {
  //       const reader = new FileReader();
  //       let files = [];
  //       this.state.imageFile.forEach((element) => {
  //         reader.readAsDataURL(element);
  //         reader.onload = () => {
  //           this.setState({ photoUrl: reader.result });
  //           files.push(reader.result);
  //         };
  //       });
  //       console.log(files, "files");
  //     }
  //   });
  // };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/variants/" + this.state.p_id} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Variant updated !</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          {this.state.warn ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-dislike-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>this Variant Already Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Update Variants</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Size </label>
                          <br />
                          {/* <Input
                      placeholder="Size "
                      name="size"
                      onChange={this.handleInput}
                      type="text"
                      value={this.state.size}
                    /> */}
                          <select
                            className="form-control"
                            name="size"
                            required={false}
                            onChange={this.handleInput}
                            value={this.state.size}
                            // defaultValue={this.state.size.size}
                          >
                            <option value="">Select an Option</option>
                            {this.state.data.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.size}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      {/* <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Pack Of</label>
                    <br />
                    <Input
                      placeholder="Pack Of"
                      name="pack_of"
                      onChange={this.handleInput}
                      value={this.state.pack_of}
                    />
                  </FormGroup>
                </Col> */}
                      {/* <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Price</label>
                    <br />
                    <Input
                      placeholder="Price"
                      name="price"
                      onChange={this.handleInput}
                      value={this.state.price}
                    />
                  </FormGroup>
                </Col> */}
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>MRP Price</label>
                          <br />
                          <Input
                            placeholder="MRP Price"
                            name="mrp_price"
                            required={true}
                            onChange={this.handleInput}
                            value={this.state.mrp_price}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Selling Price</label>
                          <br />
                          <Input
                            placeholder="Selling Price"
                            name="selling_price"
                            required={true}
                            onChange={this.handleInput}
                            value={this.state.selling_price}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Variant Quantity</label>
                          <br />
                          <Input
                            placeholder="Variant Quantity"
                            name="variant_quantity"
                            required={true}
                            onChange={this.handleInput}
                            value={this.state.variant_quantity}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Color</label>
                          <br />
                          {/* <Input
                      type="text"
                      placeholder="Color"
                      onChange={this.handleInput}
                      name="color"
                      value={this.state.color}
                    /> */}

                          <select
                            className="form-control"
                            name="color"
                            required={false}
                            onChange={this.handleInput}
                            value={this.state.color}
                          >
                            <option value="">Select an Option</option>
                            {this.state.allColor.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.color}
                              </option>
                            ))}
                          </select>

                          {/* <SketchPicker
                      // required={true}
                      color={this.state.color}
                      onChangeComplete={this.handleChangeComplete}
                    /> */}
                        </FormGroup>
                      </Col>
                      {/* <Col style={{ marginTop: "8px" }} md="6">
                  <label> Design Image</label>
                  <br />
                  <input
                    type="file"
                    onChange={this.handleFile}
                    name="design_image"
                  />
                </Col> */}

                      <Col style={{ marginTop: "8px" }} md="3">
                        <label>Video</label>
                        <br />
                        <input
                          type="file"
                          required={false}
                          onChange={(e) => {
                            this.handleVideo(e);
                            console.log(this.state.current_video, "curr_vid");
                          }}
                          name="video"
                          accept="video/*"
                        />
                      </Col>
                      <Col style={{ marginTop: "8px" }} md="12">
                        <Row>
                          {this.state.imagePreview ? (
                            <Col style={{ marginTop: "8px" }} md="6">
                              <br />
                              <img
                                width={100}
                                src={this.state.photoUrl}
                                alt=""
                              />
                            </Col>
                          ) : (
                            <Col
                              style={{
                                marginTop: "8px",
                              }}
                              md="6"
                              className="position-relative"
                            >
                              <label style={{ fontSize: "small" }}>
                                Uploaded Image:
                              </label>
                              <br />

                              {this.state.image && (
                                <>
                                  <div className="row d-flex align-items-center position-relative">
                                    <br />
                                    {this.state.image?.map((ele, index) => (
                                      <div
                                        style={{}}
                                        className="variant-img-overlay"
                                      >
                                        <img
                                          className="border m-2 p-2"
                                          width={100}
                                          src={Constants.imgUrl + ele}
                                          alt=""
                                        />
                                        <Link>
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              console.log(
                                                this.state.var_id,
                                                "dataImage"
                                              );
                                              this.setState({
                                                pop_up: true,
                                                image_index: index,
                                              });
                                            }}
                                            style={{
                                              position: "absolute",
                                              top: "45%",
                                              left: "25px",
                                            }}
                                            className=" variant-img-delete-icon"
                                            icon={faTrash}
                                          />
                                        </Link>
                                        <input
                                          accept="image/*"
                                          onChange={(e) => {
                                            this.update_image(e, index);
                                          }}
                                          style={{
                                            position: "absolute",
                                            top: "37%",
                                            width: "26px",
                                            left: "59px",
                                            borderRadius: "50%",
                                            opacity: 0,
                                            zIndex: 10,
                                            cursor: "pointer",
                                          }}
                                          type="file"
                                          multiple
                                        />
                                        <FontAwesomeIcon
                                          style={{
                                            position: "absolute",
                                            top: "45%",
                                            left: "65px",
                                          }}
                                          className=" variant-img-delete-icon"
                                          icon={faPen}
                                        />
                                      </div>
                                    ))}
                                    <div
                                      onClick={() => {}}
                                      className="position-relative d-flex align-items-center justify-content-center p-2"
                                      style={{
                                        height: "100px",
                                        width: "100px",
                                        backgroundColor: "#80808038",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="p-2"
                                        style={{ color: "white" }}
                                        icon={faAdd}
                                      />
                                      <input
                                        accept="image/*"
                                        style={{
                                          position: "absolute",
                                          top: "37%",
                                          right: "37%",
                                          opacity: "0",
                                          width: "30px",
                                          borderRadius: "50%",
                                        }}
                                        type="file"
                                        multiple
                                        onChange={this.handleFile}
                                        required={!this.state.image.length > 0}
                                        name="imageupload"
                                      />
                                    </div>
                                  </div>{" "}
                                </>
                              )}
                              {/* {this.state.image[0] ? (
                                <div>
                                  <img
                                    width={100}
                                    src={Constants.imgUrl + this.state.image[0]}
                                    alt=""
                                  />
                                  <img
                                    width={100}
                                    src={Constants.imgUrl + this.state.image[1]}
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <img
                                  img
                                  width={100}
                                  src={Constants.imgUrl + this.state.image[0]}
                                  alt=""
                                />
                              )} */}
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col md={"12"}>
                        {this.state.prev_video && (
                          <>
                            <div>
                              <label
                                className="mb-2"
                                style={{ fontSize: "small" }}
                              >
                                Uploaded Video:
                              </label>
                            </div>
                            <video width="320" height="240" controls>
                              <source
                                src={Constants.imgUrl + this.state.prev_video}
                                type="video/mp4"
                              />
                            </video>
                          </>
                        )}
                      </Col>

                      {/* <Col className="pr-1" md="3">
                  <FormGroup>
                    <label>Price</label>
                    <br />
                    <Input
                      type="number"
                      placeholder="Price"
                      name="price"
                      onChange={this.handleInput}
                      value={this.state.price}
                    />
                  </FormGroup>
                </Col> */}
                    </Row>

                    <Row className="mt-5">
                      <Col className="pr-1" md="6">
                        <Button
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          {this.state.loading
                            ? "Updating..."
                            : "  Update Variant"}
                        </Button>
                        <Link to={`/admin/variants/${this.state.p_id}`}>
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* // delete_pop_up */}
          <div
            className="p-4"
            style={{
              display: this.state.pop_up ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: "1px 1px 10px 10px #00000024",
              backgroundColor: "white",
              zIndex: 9,
            }}
          >
            <span className="p-2 mb-2">
              Are You Sure, You Want to Delete this Image?
            </span>
            <div
              className="d-flex justify-content-center"
              style={{ width: "50%" }}
            >
              <button
                onClick={() => {
                  this.delete_image();
                }}
                className="btn btn-danger"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  this.setState({ pop_up: false });
                }}
                className="btn btn-success"
              >
                No
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}
