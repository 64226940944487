import React, { useEffect, useState } from "react";

function SelectOrganelle({ onChange, options, value, onSelect, label }) {
  const [inputValue, setInputValue] = useState(value);
  const [visibility, setVisibility] = useState(false);

  return (
    <>
      <div
        className="select_organalle"
        style={{ zIndex: "8", position: "relative" }}
      >
        {label}
        <>
          <div
            style={{
              boxSizing: "border-box",
            }}
          >
            <input
              onClick={() => {
                setVisibility(true);
              }}
              onChange={(e) => {
                console.log(e.target.value);
                onChange(e.target.value);
                setVisibility(true);
                e.target.value.length < 0 && setVisibility(false);
              }}
              value={value?.label}
              className="px-2"
              style={{
                width: "100%",
                border: "1px solid grey",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "1px 1px 10px 10px #80808012",
              }}
              placeholder="search..."
              type="text"
            />
            <div
              style={{
                position: "absolute",
                display: visibility ? "flex" : "none",
                backgroundColor: "white",
                border: "1px solid grey",
                marginTop: "5px",
                boxShadow: "1px 1px 10px 10px #80808012",
                flexDirection: "column",
                width: "100%",
              }}
              className="dropdown py-2 flex-column border"
            >
              <span style={{ color: "grey" }} className="px-3">
                Select an Option{" "}
              </span>

              {options.length > 0 &&
                typeof options === "object" &&
                options.map((option) => (
                  <span
                    className="options px-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onSelect(option);
                      setInputValue(option.label);
                      setVisibility(false);
                    }}
                  >
                    {option.label}
                  </span>
                ))}
            </div>
          </div>
        </>
      </div>
      {/* overlay */}
      <div
        onClick={() => {
          setVisibility(false);
          setInputValue("");
        }}
        style={{
          display: visibility ? "block" : "none",
          position: "fixed",
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          zIndex: "7",
          backgroundColor: "00000036",
        }}
      ></div>
    </>
  );
}

export default SelectOrganelle;
