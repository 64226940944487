import React, { Component } from "react";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const classes = makeStyles(styles);

class AddBrand extends Component {
  state = {
    name: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    super_category: "",
    data: [],
    image: "",
    // brands: [],
    // image Preview
    imageFile: "",
    photoUrl: "",
    imagePreview: false,
  };

  // componentDidMount() {
  //   this.getBrands();
  // }
  // getBrands = () => {
  //   axios.get(Constants.getUrls.brand).then((response) => {
  //     console.log(response.data.getBrand.docs);
  //     this.setState({ brands: response.data.getBrand.docs });
  //   });
  // };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  // image Preview handle
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();

    fd.append("title", this.state.name);
    fd.append("image", this.state.image);
    axios.post(Constants.postUrls.addBrand, fd).then((resp) => {
      // console.log(resp.data);
      if (resp.data.status === "success") {
        fd.delete("image");
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/brand"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Brand Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This Brand Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Brand</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            style={{ textTranform: "capitalize" }}
                            labelText="Name"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ name: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Row>
                            <GridItem xs={12} sm={12} md={6}>
                              <div>
                                <label>Image (500*500)</label>
                                <br />
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    this.handleFile(e);
                                    this.handlePreview(e);
                                    this.setState({ imagePreview: true });
                                  }}
                                  required={true}
                                  name="image"
                                />
                              </div>
                            </GridItem>
                            {this.state.imagePreview ? (
                              <GridItem xs={12} sm={12} md={6}>
                                <div>
                                  <label>Image Preview</label>
                                  <br />
                                  <img
                                    width={100}
                                    src={this.state.photoUrl}
                                    alt=""
                                  />
                                </div>
                              </GridItem>
                            ) : null}
                          </Row>
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={6}></GridItem> */}
                      </GridContainer>

                      <Row className="mt-5">
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Brand"}
                          </Button>
                          <Link to="/admin/brand">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddBrand;
