import React, { Component } from "react";
import { render } from "react-dom";
import ColorPicker from "react-color-picker";
import "react-color-picker/index.css";
import { PhotoshopPicker, SketchPicker } from "react-color";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import init from "../Helper/WindowToken";
import { GetColorName } from "hex-color-to-color-name";
const fd = new FormData();
export default class AddColor extends Component {
  state = {
    name: "",
    color: "",
    image: "",
    description: "",
    notification: false,
    loading: false,
    warn: false,
    message: "",
    background: "",
    colorName: "",
    editFunctionality: false,
  };

  // ///// sketch picker //////
  handleChangeComplete = (color) => {
    this.setState({
      background: color.hex,
      // colorName: GetColorName(color.hex),
    });
    console.log(this.state.background, "color code");
  };

  handleFile = (e) => {
    console.log(e.target.files);
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();
    fd.append("color", this.state.colorName);
    fd.append("color_code", this.state.background);
    // fd.append("title", this.state.name);
    // fd.append("image", this.state.image);
    axios.post(Constants.postUrls.addColor, fd).then((resp) => {
      console.log(resp, "color post response");
      fd.delete("image");
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/color"} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong> {this.state.message} </strong> */}
                  <strong>Color Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          {this.state.warn ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-dislike-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong>Some Error Occured!</strong> */}
                  <strong>This Color Already Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add Color</CardTitle>
                </CardHeader>
                <CardBody className="">
                  <Form className="col-12 d-flex" onSubmit={this.handleSubmit}>
                    <div className="col-3">
                      <SketchPicker
                        color={this.state.background}
                        onChangeComplete={this.handleChangeComplete}
                      />
                    </div>
                    <div className="col-5 d-flex flex-column justify-content-center">
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label htmlFor="">Code: </label>
                            <Input
                              disabled
                              value={this.state.background}
                              name="color_code"
                              placeholder="#000000"
                            ></Input>
                          </FormGroup>
                          <FormGroup>
                            <label>Name: </label>
                            <Input
                              placeholder="Black"
                              value={this.state.colorName}
                              name="color"
                              required={true}
                              type="text"
                              onChange={(e) => {
                                this.setState(
                                  { colorName: e.target.value },
                                  () => {
                                    // this.handleInput(e);
                                    // console.log(this.state.colorName);
                                  }
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {/* ////// Sketch Picker /////// */}

                        {/* <Col>
                          <FormGroup>
                            <label>Description</label>
                            <Input
                              placeholder="Description"
                              value={this.state.description}
                              name="description"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="6">
                          <label>Image</label>
                          <br />
                          <input
                            type="file"
                            name="image"
                            onChange={this.handleFile}
                            // required={true}
                            accept="image/*"
                          />
                        </Col>
                        <Col className="pr-1" md="6">
                            <label>Uploaded Image</label>
                            <br />
                            <img
                              src={Constants.imgUrl + this.state.image}
                              alt="image"
                            />
                          </Col>  */}
                      </Row>

                      {/* ////// buttons /////// */}
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Color"}
                          </Button>
                          {/* <Button
                            onClick={() => {
                              this.setState({ editFunctionality: true });
                            }}
                          >
                            Edit Name
                          </Button> */}
                          <Link to="/admin/color">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </div>

                    {/* ///// input /////// */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
