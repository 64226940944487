import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import Constants from "variables/Constants";
import axios from "axios";

function UserDetails() {
  const parameters = useParams();
  const parameterId = parameters.id;
  console.log(parameterId);

  const [order, setOrder] = useState({});

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = () => {
    const mainDomain = "https://core.gifty60.com/";
    axios
      .get(`${mainDomain}auth/userDetails/${parameterId}`)
      .then((response) => {
        console.log(response.data);
        setOrder(response.data.getUser);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="card">
              <div className="card-header">
                <h6 className="mb-0">User Details</h6>
              </div>
              <div className="card-body d-flex justify-content-between row">
                <h5 className="col-6">
                  <span className="text-muted">Email ID : </span>
                  <span className="fw-bold">{order?.email}</span>
                </h5>

                <h5 className="col-6">
                  <span className="text-muted">Mobile Number: </span>
                  <span>{order?.mobile_no}</span>
                </h5>

                <h5 className="col-6">
                  <span className="text-muted">Name: </span>
                  <span className="fw-bold text-uppercase">{order?.name}</span>
                </h5>
              </div>
            </div>
            {/* <div className="card mt-4"> */}
            {/* <div className="card-header">
                <h6 className="mb-0">Product Details</h6>
              </div> */}
            {/* <div className="card-body row"> */}
            {/* <h5 className="col-6">
                  <span className="fw-bold">
                    <Link
                      to={
                        "/product_details/" +
                        order.product?._id +
                        "?variant=" +
                        order.variant?._id
                      }
                    >
                      {order.product?.name}
                    </Link>
                  </span>
                </h5> */}
            {/* <h5 className="col-6">
                  <span className="text-muted">Quantity :</span>
                  <span className={`fw-bold text-uppercase`}>
                    {order.quantity}
                  </span>
                </h5>
                <h5 className="col-6">
                  <span className="text-muted">Amount : </span>
                  <span className="fw-bold text-uppercase text-success">
                    SAR {order.total}
                  </span>
                </h5>
                <h5 className="col-6">
                  <span className="text-muted">Color : </span>
                  <span className="fw-bold text-uppercase">
                    {" "}
                    {order.variant?.color?.color}
                  </span>
                </h5>
                <h5 className="col-6">
                  <span className="text-muted">Size : </span>
                  <span className="fw-bold text-uppercase">
                    {" "}
                    {order.variant?.size?.size}
                  </span>
                </h5> */}
            {/* </div> */}
            {/* </div> */}
            {/* <div className="card mt-4"> */}
            {/* <div className="card-header">
                <h6 className="mb-0">Address Details</h6>
              </div> */}
            {/* <div className="card-body row"> */}
            {/* <h5 className="col-6">
                  <span className="text-muted">Name :</span>
                  <span className={`fw-bold text-uppercase`}>
                    {" "}
                    {order.name}
                  </span>
                </h5>
                <h5 className="col-6">
                  <span className="text-muted">Mobile No. :</span>
                  <span className={`fw-bold text-uppercase`}>
                    {order.mobile_no}
                  </span>
                </h5> */}
            {/* <h5 className="col-6">
                  <span className="text-muted">Address: </span>
                  <span className="fw-bold">
                    {order.address?.address_line1},
                    {order.address?.address_line2}
                  </span>
                </h5>
                <h5 className="col-6">
                  <span className="text-muted">City: </span>
                  <span className="fw-bold"> {order.address?.city}</span>
                </h5>
                <h5 className="col-6">
                  <span className="text-muted">Country: </span>
                  <span className="fw-bold">{order.address?.Country}</span>
                </h5> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDetails;
