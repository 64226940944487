/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import cncLogo from "../../assets/img/logo.png";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import Constants from "variables/Constants";
import routes from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, fas } from "@fortawesome/free-solid-svg-icons";
import image from "../../assets/pcPalace.jpeg";

class Sidebar extends React.Component {
  componentDidMount() {}
  state = {
    collapseOpen: false,

    // sidebar filter functionality

    filterRoutes: routes,
    // clearBtn: false,
    searchLength: 0,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // adding search func

  handleSearch = (e) => {
    const value = e.target.value;
    console.log(value);
    // const filtered = users.filter(user => user.name.includes())
    const filtered = routes.filter((route) =>
      route.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(filtered);
    this.setState({ filterRoutes: filtered });
  };
  // adding function to clear the input tag

  clearText = (e) => {
    let inputSearch = document.getElementById("inputSearch");
    inputSearch.value = "";
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            className="hover-2"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <>
        <Navbar
          style={{
            overflowX: "hidden",
            background:
              bgColor ||
              "radial-gradient(circle, rgba(2,0,36,0.9949229691876751) 0%, rgba(29,124,102,1) 0%, rgba(60,158,93,1) 42%, rgba(43,92,78,1) 99%)",
            // width: this.state.miniSidebar ? "5%" : "100%",
          }}
          className="navbar-vertical fixed-left custom-box-shadow"
          expand="md"
          id="sidenav-main"
        >
          <Container fluid>
            {/* Toggler */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* Brand */}
            {logo ? (
              <NavbarBrand className="pt-0" {...navbarBrandProps}>
                <div
                  style={{ position: "relative", top: "16px", width: "135px" }}
                >
                  <img
                    alt={logo.imgAlt}
                    // className="navbar-brand-img"
                    width="100%"
                    src={image}
                  />
                </div>
              </NavbarBrand>
            ) : null}

            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  {logo ? (
                    <Col className="collapse-brand" xs="6">
                      {logo.innerLink ? (
                        <Link to={logo.innerLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </Link>
                      ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </a>
                      )}
                    </Col>
                  ) : null}
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={this.toggleCollapse}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              {/* Form */}
              <Form className="mt-4 mb-3 d-md-none">
                <InputGroup className="input-group-rounded input-group-merge">
                  <Input
                    aria-label="Search"
                    className="form-control-rounded form-control-prepended"
                    placeholder="Search"
                    type="search"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <span className="fa fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
              {/* Navigation */}
              {/*////// Adding search ////// filter */}
              <div className="position-relative">
                <FormGroup style={{ width: 200 }}>
                  <Input
                    id="inputSearch"
                    placeholder="Search"
                    name="name"
                    onChange={(e) => {
                      this.handleSearch(e);
                      this.setState({
                        searchLength: e.target.value.length,
                      });
                    }}
                    type="text"
                    style={{ width: 150 }}
                  />
                </FormGroup>
                <div
                  className="clearBtn"
                  onClick={(e) => {
                    this.setState({ searchLength: 0, filterRoutes: routes });
                    this.clearText();
                  }}
                  style={{
                    display: this.state.searchLength > 0 ? "block" : "none",
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
              </div>

              <Nav className="text-light" navbar>
                {this.createLinks(this.state.filterRoutes)}
              </Nav>
              <button
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/signin";
                }}
                style={{ marginTop: "20px" }}
                className="button-paper"
              >
                Logout
              </button>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
