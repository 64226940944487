import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  //// add variant=================
  Input,
  Form,
  UncontrolledAlert,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Constants from "variables/Constants";
import Modal from "react-awesome-modal";
import SelectOrganelle from "components/Organelles/SelectOrganelle";

export default class Variants extends Component {
  state = {
    getVariants: [],
    // data:[],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "100",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    pr_id: this.props.match.params.id,
    // pr_id: this.props.match.params.id,
    //addvariant=====================
    data: [],
    allColor: [],
    size: "",
    color: "",
    // pack_of: "",
    image: "",
    // design_image: "",
    // price: "",
    mrp_price: "",
    selling_price: "",
    variant_quantity: "",
    video: [],
    videoAdded: false,
    imgAdd: false,
    notification: false,
    loading: false,
    message: "",
    // image preview
    imageFile: "",
    photoUrl: "",
    imagePreview: false,
    show_video: false,

    p_id: this.props.match.params.id,
    sizeValue: { value: "", label: "" },
    type_value: "",
    colorValue: { value: "", label: "" },
    // searchText: "",
  };
  componentDidMount() {
    // console.log(this.state.pr_id);
    this.getVariants();
    //add variant=================
    this.getAllSize();
    this.getAllColor();
  }
  getVariants = () => {
    axios
      .get(
        Constants.getUrls.getVariants +
          "/" +
          this.state.pr_id +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&is_delete=no"
      )
      .then((res) => {
        console.log(res, "rom");
        let btnIdArray = [];
        for (let i = 1; i <= res.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            getVariants: res.data.docs,
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            total: res.data.docs.totalDocs,
            from: 1,
            to: res.data.docs.length,
            // loading:true,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  getAllSize = (e = "") => {
    this.setState({ type_value: e }, () => {
      axios
        .get(
          // Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1"
          Constants.getUrls.getSize +
            "?limit=10" +
            "&skip=0&page=" +
            this.state.active +
            "&is_delete=no" +
            "&status=ACTIVE&q=" +
            this.state.type_value
        )
        .then((resp) => {
          console.log(resp.data._sizes.docs, "size");
          this.setState({
            data: resp.data._sizes.docs,
          });
        });
    });
  };
  getAllColor = (searchText = "") => {
    axios
      .get(
        // Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1"
        Constants.getUrls.getColor +
          "?limit=10" +
          "&skip=0&page=" +
          this.state.active +
          "&is_delete=no" +
          "&status=ACTIVE&q=" +
          searchText
      )
      .then((resp) => {
        // console.log(resp, "rr");
        this.setState({
          allColor: resp.data._colors.docs,
        });
      });
  };
  selectSize = (size) => {
    console.log(size, "size text");
    this.setState({ sizeValue: size }, () => {
      console.log(this.state.sizeValue, "sizeValue");
      this.getAllSize();
    });
  };
  selectColor = (color) => {
    this.setState({ colorValue: color });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files, imgAdd: true });
  };
  // image preview handle
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };

  handleVideo = (e) => {
    this.setState({ [e.target.name]: e.target.files[0], videoAdded: true });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();

    let fd = new FormData();
    if (this.state.videoAdded) {
      fd.append("video", this.state.video);
    }
    if (this.state.sizeValue?.value.length > 0) {
      fd.append("size", this.state.sizeValue.value);
    }
    if (this.state.colorValue?.value.length > 0) {
      fd.append("color", this.state.colorValue?.value);
    }

    // fd.append("pack_of", this.state.pack_of);
    if (this.state.image.length > 0) {
      for (let i = 0; i < this.state.image.length; i++) {
        fd.append("image", this.state.image[i]);
      }
    }
    // for (let i = 0; i < this.state.design_image.length; i++) {
    //   fd.append("design_image", this.state.design_image[i]);
    // }
    // fd.append("price", this.state.price);
    fd.append("mrp_price", this.state.mrp_price);

    fd.append("selling_price", this.state.selling_price);

    fd.append("variant_quantity", this.state.variant_quantity);
    fd.append("p_id", this.state.p_id);

    axios.post(Constants.postUrls.postVariants, fd).then((res) => {
      console.log(res, "rommm");
      if (res.data.status === "success") {
        fd.delete("image");
        this.setState({
          notification: true,
          loading: false,
          size: "",
          color: "",
          // pack_of: "",
          // price: "",
          mrp_price: "",
          selling_price: "",
          variant_quantity: "",
        });
        setTimeout(() => {
          window.location.reload();
          this.setState(
            {
              // redirect: true,

              notification: false,
            },
            () => {
              this.getVariants();
            }
          );
        }, 3000);
      } else {
        this.setState({
          warning: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warning: false,
          });
        }, 1000);
      }
    });
    // };
  };

  handleModal = (sec) => {
    this.setState({
      visible: true,
      rowId: sec._id,
      trendId: sec._id,
    });
  };
  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
    let payload = {
      _id: this.state.rowId,
    };
    axios
      .delete(Constants.postUrls.postVariants + "/" + this.state.rowId, payload)
      .then((res) => {
        console.log(res);
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          this.setState({ notifyDelete: false, visible: false }, () => {
            this.getVariants();
          });
        }, 2000);
      });
  };
  // handlePage = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //       fromInc: 1,
  //       active: 1,
  //       // searched: false,
  //       // name: "",
  //       toInc: 5,
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.products +
  //           "?limit=" +
  //           this.state.perPage +
  //           "&skip=0&page=" +
  //           this.state.active
  //         )
  //         .then((resp) => {
  //           //console.log(resp.data);
  //           let btnIdArray = [];
  //           for (let i = 1; i <= resp.data.pageCount; i++) {
  //             btnIdArray.push(i);
  //           }
  //           this.setState(
  //             {
  //               btnIdArray,
  //               getVariants: resp.data.variant,
  //               total: resp.data.itemCount,
  //               from: 1,
  //               to: resp.data.variant.length,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({
  //                 filteredBtnIdArray,
  //               });
  //             }
  //           );
  //         });
  //     }
  //   );
  // };

  // currentPage = (v) => {
  //   if (this.state.searched) {
  //     let commonUrl =
  //       Constants.postUrls.searchProduct +
  //       "?limit=" +
  //       this.state.perPage +
  //       "&skip=0&page=";
  //     axios.post(commonUrl + v, { name: this.state.name }).then((resp) => {
  //       console.log(resp.data);
  //       this.setState(
  //         {
  //           data: resp.data.data,
  //           total: resp.data.itemCount,
  //           from: v * this.state.perPage - (this.state.perPage - 1),
  //           to:
  //             v * this.state.perPage > this.state.total
  //               ? this.state.total
  //               : v * this.state.perPage,
  //           active: v,
  //         },
  //         () => {
  //           console.log(typeof this.state.data[0].image);
  //         }
  //       );
  //     });
  //   } else {
  //     let commonUrl =
  //       Constants.getUrls.products +
  //       "?limit=" +
  //       this.state.perPage +
  //       "&skip=0&page=";
  //     axios.get(commonUrl + v).then((resp) => {
  //       console.log(resp.data);
  //       this.setState(
  //         {
  //           data: resp.data.data,
  //           total: resp.data.itemCount,
  //           from: v * this.state.perPage - (this.state.perPage - 1),
  //           to:
  //             v * this.state.perPage > this.state.total
  //               ? this.state.total
  //               : v * this.state.perPage,
  //           active: v,
  //         },
  //         () => {
  //           console.log(typeof this.state.data[0].image);
  //         }
  //       );
  //     });
  //   }
  // };

  // prevPages = () => {
  //   this.setState(
  //     {
  //       fromInc: this.state.fromInc - 5,
  //       toInc: this.state.toInc - 5,
  //     },
  //     () => {
  //       let filteredBtnIdArray = this.state.btnIdArray.filter(
  //         (v) => v >= this.state.fromInc && v <= this.state.toInc
  //       );
  //       if (filteredBtnIdArray.length >= 1) {
  //         if (filteredBtnIdArray.length === 1) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 4,
  //               toInc: this.state.toInc + 4,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 2) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 3,
  //               toInc: this.state.toInc + 3,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 3) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 2,
  //               toInc: this.state.toInc + 2,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 4) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc + 1,
  //               toInc: this.state.toInc + 1,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else {
  //           this.setState({ filteredBtnIdArray });
  //         }
  //       } else {
  //         this.setState(
  //           {
  //             fromInc: this.state.fromInc + 5,
  //             toInc: this.state.toInc + 5,
  //           },
  //           () => {
  //             let filteredBtnIdArray = this.state.btnIdArray.filter(
  //               (v) => v >= this.state.fromInc && v <= this.state.toInc
  //             );
  //             this.setState({ filteredBtnIdArray });
  //           }
  //         );
  //       }
  //     }
  //   );
  // };

  // nextPages = () => {
  //   this.setState(
  //     {
  //       fromInc: this.state.fromInc + 5,
  //       toInc: this.state.toInc + 5,
  //     },
  //     () => {
  //       let filteredBtnIdArray = this.state.btnIdArray.filter(
  //         (v) => v >= this.state.fromInc && v <= this.state.toInc
  //       );
  //       if (filteredBtnIdArray.length >= 1) {
  //         if (filteredBtnIdArray.length === 1) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 4,
  //               toInc: this.state.toInc - 4,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 2) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 3,
  //               toInc: this.state.toInc - 3,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 3) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 2,
  //               toInc: this.state.toInc - 2,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else if (filteredBtnIdArray.length === 4) {
  //           this.setState(
  //             {
  //               fromInc: this.state.fromInc - 1,
  //               toInc: this.state.toInc - 1,
  //             },
  //             () => {
  //               let filteredBtnIdArray = this.state.btnIdArray.filter(
  //                 (v) => v >= this.state.fromInc && v <= this.state.toInc
  //               );
  //               this.setState({ filteredBtnIdArray });
  //             }
  //           );
  //         } else {
  //           this.setState({ filteredBtnIdArray });
  //         }
  //       } else {
  //         this.setState(
  //           {
  //             fromInc: this.state.fromInc - 5,
  //             toInc: this.state.toInc - 5,
  //           },
  //           () => {
  //             let filteredBtnIdArray = this.state.btnIdArray.filter(
  //               (v) => v >= this.state.fromInc && v <= this.state.toInc
  //             );
  //             this.setState({ filteredBtnIdArray });
  //           }
  //         );
  //       }
  //     }
  //   );
  // };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.getVariants +
              "/" +
              this.state.pr_id +
              "?limit=" +
              this.state.perPage +
              "&skip=0&page=" +
              this.state.active +
              "&is_delete=no" +
              "&sort=true"
          )
          .then((resp) => {
            // console.log(resp.data,"rommmm");
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                getVariants: resp.data.docs,
                total: resp.data.totalDocs,
                from: 1,
                to: resp.data.docs.length,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  currentPage = (v) => {
    let commonUrl =
      Constants.getUrls.getVariants +
      "/" +
      this.state.pr_id +
      "?limit=" +
      this.state.perPage +
      "&is_delete=no" +
      "&sort=true" +
      "&skip=0&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data);
      this.setState({
        getVariants: resp.data.docs,
        total: resp.data.totalDocs,
        from: v * this.state.perPage - (this.state.perPage - 1),
        to:
          v * this.state.perPage > this.state.total
            ? this.state.total
            : v * this.state.perPage,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <div className="content">
        <CardHeader>
          <CardTitle tag="h4" style={{ marginTop: "20px" }}>
            Variants
          </CardTitle>
          {/* <Link
            to={{
              pathname: "/admin/variants/" + this.state.p_id,
              state: { data: this.state.p_id },
            }}
          >
            <Button className="" color="success">
              Add Variant
            </Button>
          </Link> */}
          {/* ///Add variant==================== */}

          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Variant Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          {this.state.warning ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-dislike-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong>Some Error Occured!</strong> */}
                  <strong>This Variant Already Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
        </CardHeader>

        <CardBody>
          {/* /* ///Add variant====================  */}
          <br />
          <Container>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col className="pr-1" md="6">
                  <SelectOrganelle
                    label={"Sizes"}
                    value={this.state.sizeValue}
                    onChange={(searchText) => {
                      console.log(searchText);
                      this.setState(
                        {
                          sizeValue: {
                            value: "",
                            label: searchText,
                          },
                        },
                        () => {
                          this.getAllSize(searchText);
                        }
                      );
                    }}
                    options={this.state.data.map((option) => {
                      console.log(option.size, "option");
                      return {
                        label: option.size,
                        value: option._id,
                      };
                    })}
                    onSelect={this.selectSize}
                  />
                  {/* <FormGroup>
                    <label>Size </label>
                    <br />
                    
                    <select
                      className="form-control"
                      name="size"
                      required={false}
                      onChange={this.handleInput}
                    >
                      <option value="">Select an Option</option>
                      {this.state.data.map((v, i) => (
                        <option key={i} value={v._id}>
                          {v.size}
                        </option>
                      ))}
                    </select>
                  </FormGroup> */}
                </Col>

                {/* <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Pack Of</label>
                    <br />
                    <Input
                      placeholder="Pack Of"
                      name="pack_of"
                      onChange={this.handleInput}
                    // required={true}
                    />
                  </FormGroup>
                </Col> */}
                {/* <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Price</label>
                    <br />
                    <Input
                      placeholder="Price"
                      name="price"
                      onChange={this.handleInput}
                      type={"number"}
                    // required={true}
                    />
                  </FormGroup>
                </Col> */}

                <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>MRP Price</label>
                    <br />
                    <Input
                      placeholder="MRP Price"
                      name="mrp_price"
                      onChange={this.handleInput}
                      type={"number"}
                      required={true}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Selling Price</label>
                    <br />
                    <Input
                      placeholder="Selling Price"
                      name="selling_price"
                      onChange={this.handleInput}
                      type={"number"}
                      required={true}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Variant Quantity</label>
                    <br />
                    <Input
                      placeholder="Variant Quantity"
                      name="variant_quantity"
                      onChange={this.handleInput}
                      type={"number"}
                      required={true}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-1" md="6">
                  <SelectOrganelle
                    label={"Color"}
                    value={this.state.colorValue}
                    onChange={(searchText) => {
                      console.log(searchText);
                      this.setState(
                        {
                          colorValue: {
                            value: "",
                            label: searchText,
                          },
                        },
                        () => {
                          this.getAllColor(searchText);
                        }
                      );
                    }}
                    options={this.state.allColor.map((option) => {
                      console.log(option, "option");
                      return {
                        label: option.color,
                        value: option._id,
                      };
                    })}
                    onSelect={this.selectColor}
                  />
                  {/* <FormGroup>
                    <label>Color</label>
                    <br />
                    <select
                      className="form-control"
                      name="color"
                      required={false}
                      onChange={this.handleInput}
                    >
                      <option value="" style={{ height: "5px" }}>
                        Select an Option
                      </option>
                      {this.state.allColor.map((v, i) => (
                        <option key={i} value={v._id}>
                          {v.color}
                        </option>
                      ))}
                    </select>
                    
                  </FormGroup> */}
                </Col>
                {/* <Col style={{ marginTop: "8px" }} md="6">
                    <label> Design Image</label>
                    <br />
                    <input
                      type="file"
                      onChange={this.handleFile}
                      // required={true}
                      name="design_image"
                    />
                  </Col> */}
                <Col style={{ marginTop: "8px" }} md="6">
                  <Row>
                    <Col style={{ marginTop: "8px" }} md="6">
                      <label>Image (500*500)</label>
                      <br />
                      <input
                        accept="image/*"
                        type="file"
                        multiple
                        onChange={(e) => {
                          this.handleFile(e);
                          this.handlePreview(e);
                          this.setState({ imagePreview: true });
                        }}
                        required={true}
                        name="image"
                      />
                    </Col>
                    {this.state.imagePreview ? (
                      <Col style={{ marginTop: "8px" }} md="6">
                        <label>Image Preview</label>
                        <br />
                        <img width={100} src={this.state.photoUrl} alt="" />
                      </Col>
                    ) : null}
                  </Row>
                </Col>

                <Col style={{ marginTop: "8px", marginBottom: "15px" }} md="3">
                  <label>Video</label>
                  <br />
                  <input
                    required={false}
                    type="file"
                    onChange={this.handleVideo}
                    name="video"
                    accept="video/*"
                  />
                </Col>

                {/* <Col className="pr-1" md="3">
                <FormGroup>
                  <label>Price</label>
                  <br />
                  <Input
                    type="number"
                    placeholder="Price"
                    name="price"
                    onChange={this.handleInput}
                    required={true}
                  />
                </FormGroup>
              </Col> */}
              </Row>

              <Row className="mt-5">
                <Col className="pr-1" md="6">
                  <Button className="btn-round" color="success" type="submit">
                    {this.state.loading ? "Adding..." : "  Add Variant"}
                  </Button>
                  <Link to="/admin/products">
                    <Button className="btn-round" color="danger">
                      Cancel
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Container>

          <br />

          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Sr.No</th>

                <th>Size</th>
                <th>Color</th>

                {/* <th>Pack Of</th> */}
                {/* <th>Price</th> */}
                <th>MRP Price</th>
                <th>Selling Price</th>
                <th>Variant Quantity</th>
                {/* <th>Design image</th> */}
                <th>Video</th>
                <th>Image</th>
                <th>Update</th>

                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {this.state.getVariants
                ? this.state.getVariants.map((vars, i) => {
                    // console.log(vars, "romen");
                    return (
                      <tr key={i}>
                        <td style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "30px" }}>
                            {i + 1}
                          </span>
                        </td>
                        <td style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "30px" }}>
                            {vars.size?.size ? vars.size.size : "NA"}
                          </span>
                        </td>
                        {/* <td style={{ position: "relative" }}>
                        <span style={{ position: "absolute", top: "30px" }}>
                          {vars.size?.size === vars.size?.size ? vars.size.size : "NA"}</span></td> */}
                        {/* <td style={{ position: "relative" }}>
                        <span style={{ position: "absolute", top: "30px" }}>
                          {vars.color ? vars.color : "NA"}
                        </span>
                      </td> */}
                        {/* {vars.color === null ? "NA" : vars.color} */}
                        <td style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "30px" }}>
                            {vars.color?.color ? vars.color.color : "NA"}
                          </span>
                        </td>{" "}
                        {/* <td style={{ position: "relative" }}>
                        <span style={{ position: "absolute", top: "30px" }}>
                          {vars.pack_of ? vars.pack_of : "NA"}
                        </span>
                      </td> */}
                        {/* <td style={{ position: "relative" }}>
                        <span style={{ position: "absolute", top: "30px" }}>
                          {vars.price ? "Rs. " + vars.price : "NA"}
                        </span>
                      </td> */}
                        <td style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "30px" }}>
                            {vars.mrp_price ? "Rs. " + vars.mrp_price : "NA"}
                          </span>
                        </td>
                        <td style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "30px" }}>
                            {vars.selling_price
                              ? "Rs. " + vars.selling_price
                              : "NA"}
                          </span>
                        </td>
                        <td style={{ position: "relative" }}>
                          <span style={{ position: "absolute", top: "30px" }}>
                            {vars.variant_quantity
                              ? vars.variant_quantity
                              : "NA"}
                          </span>
                        </td>
                        {/* <td>
                        {vars.design_image ? (
                          <img
                            src={
                              typeof vars.design_image == "string"
                                ? Constants.imgUrl + vars.design_image
                                : Constants.imgUrl + vars.image[0]
                            }
                            width="80px"
                            alt="img"
                          />
                        ) : (
                          "NA"
                        )}
                      </td> */}
                        <td>
                          {vars.video_file ? (
                            <>
                              <span
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.setState({ show_video: true });
                                }}
                              >
                                Show Video
                              </span>
                              <div
                                className="p-4"
                                style={{
                                  display: this.state.show_video
                                    ? "flex"
                                    : "none",
                                  position: "absolute",
                                  top: "70%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  boxShadow: "1px 1px 10px 10px #00000024",
                                  backgroundColor: "white",
                                  zIndex: 9,
                                }}
                              >
                                <video width="400" height="300" controls>
                                  <source
                                    src={Constants.imgUrl + vars.video_file}
                                    type="video/mp4"
                                  />
                                </video>

                                <span
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.setState({ show_video: false });
                                  }}
                                >
                                  Close video
                                </span>
                              </div>
                              <div
                                onClick={() => {
                                  this.setState({ show_video: false });
                                }}
                                style={{
                                  display: this.state.show_video
                                    ? "block"
                                    : "none",
                                  position: "fixed",
                                  top: 0,
                                  bottom: 0,
                                  right: 0,
                                  left: 0,
                                  zIndex: 8,
                                  backgroundColor: "#00000010",
                                }}
                              ></div>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {vars.image.length > 0 ? (
                            <img
                              src={
                                typeof vars.image == "string"
                                  ? Constants.imgUrl + vars.image
                                  : Constants.imgUrl + vars.image[0]
                              }
                              width="80px"
                              alt="img"
                            />
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: "/admin/updateVariant/" + vars._id,
                              state: { data: vars },
                            }}
                          >
                            <Button
                              className="btn-round"
                              color="info"
                              type="submit"
                            >
                              Update
                            </Button>
                          </Link>
                        </td>
                        <td>
                          <Button
                            className="btn-round"
                            color="danger"
                            type="submit"
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              this.handleModal(vars, "delete");
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
            <Modal
              visible={this.state.visible}
              width="600"
              height="100"
              effect="fadeInUp"
              onClickAway={() => this.setState({ visible: false })}
            >
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Card>
                    {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                    <CardBody>
                      <div>
                        <h3 style={{ textAlign: "center" }}>
                          Are You Sure You Want to Delete It?
                        </h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Variant Deleted Successfuly!
                            </span>
                          </div>
                        ) : null}
                        <Row
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Col xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleDelete(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? "Deleting..." : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="success"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              No
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Modal>
          </Table>
          {this.state.getVariants.length === 0 ? (
            <div>
              <h6
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  fontSize: "14px",
                }}
              >
                No Data Found!
              </h6>
            </div>
          ) : (
            <div style={{ marginTop: "20px" }}>
              <div style={{ float: "left" }}>
                Showing {this.state.from} to {this.state.to} of{" "}
                {this.state.total} entries.
              </div>

              <div style={{ float: "right" }}>
                <FormGroup
                  style={{
                    position: "absolute",
                    top: "26px",
                    right: "2%",
                    width: "85px",
                  }}
                >
                  <label>Per Page</label>

                  <select
                    className="form-control"
                    name="perPage"
                    value={this.state.perPage}
                    onChange={this.handlePage}
                  >
                    {[10, 25, 50].map((category, i) => (
                      <option key={i} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                  <br />
                </FormGroup>
              </div>
              <div style={{ float: "right" }}>
                <Pagination>
                  <PaginationItem>
                    <PaginationLink onClick={this.prevPages} tabIndex="-1">
                      <i className="fa fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {this.state.filteredBtnIdArray.map((v, i) => (
                    <PaginationItem
                      className={this.state.active === v ? "active" : ""}
                      key={i}
                    >
                      <PaginationLink
                        onClick={() => {
                          this.currentPage(v);
                        }}
                      >
                        {v}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem>
                    <PaginationLink onClick={this.nextPages}>
                      <i className="fa fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            </div>
          )}
        </CardBody>
      </div>
    );
  }
}
