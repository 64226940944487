import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Table,
  Row,
  Col,
  Button,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { IconButton } from "@material-ui/core";
import "../css/ToolTipStyle.css";
import {
  faArrowTrendUp,
  faFileExport,
  faInfo,
  faInfoCircle,
  faPalette,
  faPallet,
  faPen,
  faPlus,
  faSadTear,
  faSearch,
  faShirt,
  faStar,
  faStarAndCrescent,
  faStarHalf,
  faStarHalfAlt,
  faTrash,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSadCry } from "@fortawesome/free-regular-svg-icons";
import CustomSelect from "components/Organelles/CustomSelect";
import SelectOrganelle from "components/Organelles/SelectOrganelle";
// import DeleteIcon from '@mui/icons-material/Delete';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonColor: "success",
      data: [],
      productIds: [],
      products: [],
      all_filter_product: [],
      selectedID: "",

      warn: false,
      redirect: false,
      warning: false,
      notification: false,
      loading: false,
      super_categories: [],
      super_category: "",
      __superCategories: [],
      __categories: [],
      __subCategories: [],
      categories: [],
      category: "",
      sub_categories: [],
      sub_category: "",
      brands: [],
      superCategoryValue: "",
      categoryValue: "",
      subCategoryValue: "",
      brandValue: "",

      notifyStatus: false,
      notifyDelete: false,
      visible: false,

      searching: false,
      searched: false,
      delLoading: false,

      trendId: 0,
      notifyTrending: false,
      visibleTrending: false,
      trending: false,
      trendingStatus: "",

      bestSellerId: 0,
      notifyBestSellers: false,
      visibleBestSellers: false,
      bestsellers: false,
      bestSellersStatus: "",
      rowId: 0,
      status: "",

      active: 1,
      perPage: "10",
      currentPage: 1,
      prevPage: 0,
      nextPage: 0,
      lastPage: 0,
      lastPageView: false,
      firstPageView: false,
      total: 0,
      from: 0,
      to: 0,
      fromInc: 1,
      toInc: 5,
      btnIdArray: [],
      filteredBtnIdArray: [],
      productId: "",
      dropdown: false,
      // query: "",
      ex: true,
      supercatSearch: "",
      subcatSearch: "",
      superType: "",
      catSearch: "",
      superCategoryValue: {
        value: "",
        label: "",
      },
      categoryValue: {
        value: "",
        label: "",
      },
      subCategoryValue: {
        value: "",
        label: "",
      },
      brandValue: {
        value: "",
        label: "",
      },
      type_value: "",
      csvFile: null,
    };
  }

  componentDidMount() {
    this.getProducts();
    this.fetchSuperCategories();
    this.getBrands();

    // this.fetchCategories();
    // this.fetchSubCategories();
    // axios
    //   .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1" + "&is_delete=no")
    //   .then((resp) => {
    //     console.log(resp, "rr");
    //     this.setState({
    //       super_categories: resp.data.superCat.docs,
    //     });
    //   });
  }
  handleFile = (e) => {
    this.setState({ csvFile: e.target.files[0] });
  };

  import = () => {
    const fd = new FormData();
    fd.append("file", this.state.csvFile);
    axios
      .post(Constants.postUrls.importProduct, fd)
      .then((res) => {
        console.log(res.data);
        this.getProducts();
        this.setState({
          importFileStatus: true,
          importFileMessage: "Imported successfully",
          isSuccess: true,
        });
        setTimeout(() => {
          this.setState({ importFileStatus: false });
        }, 3000);
      })
      .catch((err) => {
        if (err) {
          this.setState({
            importFileStatus: true,
            importFileMessage: "Please check the uploaded file",
            isSuccess: false,
          });
          setTimeout(() => {
            this.setState({ importFileStatus: false });
          }, 3000);
        }
      });
  };

  // to get Super Category Id and name
  fetchSuperCategories = (searchText = "") => {
    axios
      .get(
        `${Constants.getUrls.super_categories}?is_delete=no&status=ACTIVE&q=${searchText}`
      )
      .then((response) => {
        console.log(response.data.superCat.docs, "supercategories");
        this.setState({
          super_categories: response.data.superCat.docs,
        });
      });
  };

  // to get Category Id and name
  fetchCategories = (searchText = "") => {
    axios
      .get(
        Constants.getUrls.categories +
          "?super_category=" +
          this.state.superCategoryValue.value +
          "&q=" +
          searchText
      )
      .then((response) => {
        console.log(response.data.docs);
        this.setState({
          categories: response.data.docs,
        });
      });
  };
  // to get sub categories id and name
  fetchSubCategories = (searchText = "") => {
    axios
      .get(
        Constants.getUrls.subCategories +
          "?category=" +
          this.state.categoryValue.value +
          "&q=" +
          searchText
      )
      .then((response) => {
        console.log(response.data.docs, "subcat");
        this.setState({
          sub_categories: response.data.docs,
        });
      });
  };
  getProducts = () => {
    axios
      .get(
        Constants.getUrls.products +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          "&sort=true" +
          this.state.active +
          "&q=" +
          this.state.name +
          "&is_delete=no" +
          "&super_category=" +
          this.state.superCategoryValue?.value +
          "&category=" +
          this.state.categoryValue?.value +
          "&sub_category" +
          this.state.subCategoryValue?.value +
          "&brand=" +
          this.state.brandValue?.value
      )
      .then((response) => {
        console.log(response.data, "products");
        let btnIdArray = [];
        for (let i = 1; i <= response.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            data: response.data.docs,
            total: response.data.totalDocs,
            from: 1,
            to: response.data.docs?.length,
            type_value: "",
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  getBrands = (e = "") => {
    this.setState({ type_value: e }, () => {
      axios
        .get(
          `${Constants.getUrls.brand}?is_delete=no&status=ACTIVE&q=${this.state.type_value}`
        )
        .then((response) => {
          console.log(response.data.getBrand.docs, "brand");
          this.setState({ brands: response.data.getBrand.docs });
        });
    });
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        searched: false,
        name: "",
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.products +
              "?limit=" +
              this.state.perPage +
              "&is_delete=no" +
              "&skip=0&page=" +
              this.state.active
          )
          .then((resp) => {
            // console.log(resp.data, "total pages");
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                data: resp.data.docs,
                total: resp.data.totalDocs,
                from: 1,
                to: resp.data.docs.length,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    if (this.state.searched) {
      let commonUrl =
        Constants.postUrls.searchProduct +
        "?limit=" +
        this.state.perPage +
        "&is_delete=no" +
        "&sort=true" +
        "&skip=0&page=";

      axios.post(commonUrl + v, { name: this.state.name }).then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            data: resp.data.docs,
            total: resp.data.totalDocs,
            from: v * this.state.perPage - (this.state.perPage - 1),
            to:
              v * this.state.perPage > this.state.total
                ? this.state.total
                : v * this.state.perPage,
            active: v,
            currentPage: v,
          },
          () => {
            console.log(typeof this.state.data[0].image);
          }
        );
      });
    } else {
      let commonUrl =
        Constants.getUrls.products +
        "?limit=" +
        this.state.perPage +
        "&is_delete=no" +
        "&skip=0&page=";
      axios.get(commonUrl + v).then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            data: resp.data.docs,
            total: resp.data.totalDocs,
            from: v * this.state.perPage - (this.state.perPage - 1),
            to:
              v * this.state.perPage > this.state.total
                ? this.state.total
                : v * this.state.perPage,
            active: v,
            currentPage: v,
          },
          () => {
            // console.log(typeof this.state.data[0].image);
          }
        );
      });
    }
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post(Constants.statusUrl + id + "?type=product", payload)
          .then(() => {
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState({ notifyStatus: false }, () => {
                this.getProducts();
              });
            }, 1000);
          });
      }
    );
  };

  handleDelete = (id) => {
    console.log(id, "delete id");
    console.log(Constants.getUrls.products + "/" + id, "delete url");
    this.setState({ delLoading: true });
    axios.delete(Constants.getUrls.products + "/" + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        this.setState({ notifyDelete: false, visible: false }, () => {
          this.getProducts();
        });
      }, 2000);
    });
  };

  handleTrending = (id) => {
    this.setState({ trending: true });
    axios
      .post(Constants.postUrls.setTrending + id, {
        trending_status: this.state.trendingStatus,
      })
      .then(() => {
        this.setState({
          notifyTrending: true,
          trending: false,
        });
        setTimeout(() => {
          this.setState(
            { notifyTrending: false, visibleTrending: false },
            () => {
              this.getProducts();
            }
          );
        }, 2000);
      });
  };

  handleModal = (sec, arg, status) => {
    this.setState({
      rowId: sec._id,
      trendId: sec._id,

      trendingStatus: status,
      bestSellersStatus: status,

      bestSellerId: sec._id,
      visible: arg === "bestsellers" ? false : true,
      visibleBestSellers: arg === "bestsellers" ? true : false,

      visible: arg === "trending" ? false : true,
      visibleTrending: arg === "trending" ? true : false,
    });
  };

  // best seller status=====
  handleBestseller = (product) => {
    console.log(product.bestseller_status);
    this.setState({ bestsellers: true });

    if (product.bestseller_status === "yes") {
      axios
        .post(Constants.postUrls.setBestSeller + product._id, {
          bestseller_status: "no",
        })
        .then((resp) => {
          console.log(resp);
          this.setState({
            notifyBestSellers: true,
            bestsellers: false,
          });
          setTimeout(() => {
            this.setState(
              { notifyBestSellers: false, visibleBestSellers: false },
              () => {
                this.getProducts();
              }
            );
          }, 2000);
        });
    } else {
      axios
        .post(Constants.postUrls.setBestSeller + product._id, {
          bestseller_status: "yes",
        })
        .then((resp) => {
          console.log(resp);

          this.setState({
            notifyBestSellers: true,
            bestsellers: false,
          });
          setTimeout(() => {
            this.setState(
              { notifyBestSellers: false, visibleBestSellers: false },
              () => {
                this.bestSellerSubmit(product);
                this.getProducts();
              }
            );
          }, 2000);
        });
    }
  };

  // add=======
  bestSellerSubmit = (product) => {
    console.log(product, "rom");

    let payLoad = {
      super_category: product?.super_category?._id,
      category: product?.category?._id,
      sub_category: product?.sub_category?._id,
      products: product?._id,
    };
    // console.log(payLoad, "romen");
    axios.post(Constants.getUrls.bestSellers, payLoad).then((resp) => {
      // console.log(resp, "romen");
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            notification: false,
            // buttonColor:'orange'
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warning: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warning: false,
          });
        }, 1000);
      }
    });
  };

  productSearch = () => {
    console.log(this.state.data);

    this.setState({ searching: true });
    axios
      .post(
        Constants.postUrls.searchProduct +
          "?limit=" +
          this.state.perPage +
          "&is_delete=no" +
          "&skip=0&page=1",
        { name: this.state.name }
      )
      .then((resp) => {
        console.log(resp.data, "btn");
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.pageCount; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            data: resp.data.data,
            total: resp.data.totalDocs,
            from: 1,
            fromInc: 1,
            active: 1,
            toInc: 5,
            searched: true,
            searching: false,
            to: resp.data?.data?.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handleDownload = (
    _id,
    super_category,
    category,
    name,
    short_description,
    product_return,
    product_exchange,
    delivery_amount,
    mrp_price,
    selling_price,
    status,
    createdAt
  ) => {
    // console.log(name,image, mobile_no)
    let payload = {
      // "columns":[name,image,mobile_no]
      columns: [
        "_id",
        "super_category",
        "category",
        "sub_category",
        "name",
        "short_description",
        "product_return",
        "product_exchange",
        "delivery_amount",
        "mrp_price",
        "selling_price",
        "status",
        "createdAt",
      ],
    };
    axios
      .post(Constants.postUrls.downloadCsvProduct, payload, {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDI0NDFlYjFlNDZiOTNkYzFhNDcxODQiLCJpYXQiOjE2ODEyNzkzNjB9.4J-fKMcFuuYKIOVLcqKiKzHNxjG8EFTmtDLdhdv-pIU",
        },
      })
      .then((res) => {
        console.log("@@@", res.data.url);
        if (res.data) {
          if (res.data.url) {
            let baseurl = Constants.imgUrl + res.data.url;
            window.open(baseurl, "_blank");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // let data = JSON.stringify({
    //   "columns": [
    //     name,
    //     email,
    //     mobile_no,
    //     dob
    //   ]
    // });

    // console.log(data)

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'https://core.gifty60.com/user/exportExcel',
    //   headers: {
    //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDI0NDFlYjFlNDZiOTNkYzFhNDcxODQiLCJpYXQiOjE2ODEyNzkzNjB9.4J-fKMcFuuYKIOVLcqKiKzHNxjG8EFTmtDLdhdv-pIU',
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };

    // axios.request(config)
    // .then((response) => {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  // handleCatgory = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //       category: ""
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.categories +
  //           "?limit=50&page=1&status=active" +
  //           `&super_category=` +
  //           this.state.super_category + "&is_delete=no"
  //         )
  //         .then((resp) => {
  //           // console.log(resp);
  //           this.setState({
  //             categories: resp.data.docs,
  //           });
  //         });
  //     }
  //   );
  // };

  // handleSubCategory = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.subCategories +
  //           "?limit=50&page=1&status=active" +
  //           "&category=" +
  //           this.state.category +
  //           `&super_category=` +
  //           this.state.super_category + "&is_delete=no"
  //         )
  //         .then((resp) => {
  //           // console.log(resp);
  //           this.setState({
  //             sub_categories: resp.data.docs,
  //           });
  //         });
  //     }
  //   );
  // };
  // seach_functionality = (e) => {
  //   axios.get(`Constants.getUrls.brand?q=${e.target.value}`).then((r)=>{
  //     this.setState({
  //       filtered_array: r.data.getBrand.docs
  //     })
  //   })
  // }
  selectBrand = (brand) => {
    this.setState({ brandValue: brand }, () => {
      console.log(this.state.brandValue, "brandValue");
      this.getProducts();
    });
  };
  selectSuperCat = (supercat) => {
    console.log(supercat);
    this.setState(
      {
        superCategoryValue: supercat,
        categoryValue: {
          label: "",
          value: "",
        },
        subCategoryValue: {
          label: "",
          value: "",
        },
      },
      () => {
        console.log(this.state.superCategoryValue, "supervalue");
        this.getProducts();
        this.fetchCategories();
      }
    );
  };
  selectCat = (cat) => {
    this.setState(
      {
        categoryValue: cat,
        subCategoryValue: {
          label: "",
          value: "",
        },
      },
      () => {
        this.getProducts();
        this.fetchSubCategories();
      }
    );
  };
  selectSubCat = (subcat) => {
    this.setState(
      {
        subCategoryValue: subcat,
      },
      () => {
        this.getProducts();
      }
    );
  };
  render() {
    const { buttonColor } = this.state;
    const { currentPage } = this.state;
    const totalPages = 10; // Total number of pages
    return (
      <>
        <div className="content">
          {this.state.importFileStatus ? (
            <div
              style={{
                position: "absolute",
                right: 18,
                top: 18,
                zIndex: 99999,
                borderRadius: 4,
                padding: "12px",
                backgroundColor: this.state.isSuccess ? "green" : "red",
              }}
            >
              <span className="alert-inner--text text-white">
                <strong>{this.state.importFileMessage}</strong>
              </span>
            </div>
          ) : null}
          {this.state.notifyStatus ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Status Changed!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          <Row>
            <Col md="12">
              <Card style={{ position: "relative" }}>
                <CardHeader className="bg-black">
                  <CardTitle className="page-heading" tag="h4">
                    Products
                  </CardTitle>

                  <div className="col-12 d-flex">
                    {/* /// add product & export to csv //// */}
                    <div className="col-4 d-flex align-items-center">
                      <div className="d-flex align-items-center mr-2">
                        {/* <span className="sub-heading">Add Product </span> */}
                        <Link to="/admin/addproduct">
                          {/* <FontAwesomeIcon
                            className="sub-heading-icon px-2"
                            icon={faPlus}
                          /> */}
                          <Button className="" color="success">
                            Add Product
                          </Button>
                        </Link>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "10" }}
                      >
                        {/* <span className="sub-heading">Export to CSV </span> */}
                        <Link>
                          {/* <FontAwesomeIcon
                            className="sub-heading-icon px-2"
                            icon={faFileExport}
                            onClick={() => {
                              this.handleDownload();
                            }}
                          /> */}
                          <Button className="" color="danger">
                            Export to CSV
                          </Button>

                          {/* <span
                            onClick={() => {
                              this.handleDownload();
                            }}
                            className="sub-heading"
                          >
                            Export to CSV{" "}
                          </span> */}
                        </Link>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* <span className="sub-heading">Export to CSV </span> */}
                        <CardHeader>
                          <label>File</label>
                          {/* <br /> */}
                          <div className="d-flex">
                            <input
                              type="file"
                              onChange={this.handleFile}
                              name="image"
                              accept=".csv"
                            />
                            <Button
                              onClick={this.import}
                              className=""
                              color="danger"
                            >
                              {this.state.importing ? "Importing..." : "Import"}
                            </Button>
                          </div>
                        </CardHeader>
                      </div>
                    </div>
                  </div>
                  {/* ///// filters ////// */}
                  <div className="col-12 d-flex mt-3">
                    {/* superCategories */}

                    <div className="col-3">
                      <SelectOrganelle
                        label={"Select Super Category"}
                        value={this.state.superCategoryValue}
                        onChange={(searchText) => {
                          console.log(searchText);
                          this.setState(
                            {
                              superCategoryValue: {
                                value: "",
                                label: searchText,
                              },
                            },
                            () => {
                              this.fetchSuperCategories(searchText);
                            }
                          );
                        }}
                        options={this.state.super_categories.map((option) => {
                          return {
                            label: option.title,
                            value: option._id,
                          };
                        })}
                        onSelect={this.selectSuperCat}
                      />
                      {/* <FormGroup>
                        <label>Super Category</label>
                        <br />
                        <select
                          style={{ height: "2.8rem" }}
                          className="form-control filters"
                          name="perPage"
                          value={this.state.superCategoryValue}
                          onChange={(e) => {
                            this.setState(
                              { superCategoryValue: e.target.value },
                              () => {
                                this.getProducts();
                                this.fetchCategories();
                              }
                            );
                          }}
                        >
                          <option selected="true" disabled value={""}>
                            Select an Option
                          </option>
                          {this.state.__superCategories.map(
                            (superCategory, i) => (
                              <>
                                <option key={i} value={superCategory._id}>
                                  {superCategory.title}
                                </option>
                              </>
                            )
                          )}
                        </select>
                      </FormGroup> */}
                    </div>
                    {/* catgories */}
                    <div className="col-3">
                      <SelectOrganelle
                        label={"Category"}
                        value={this.state.categoryValue}
                        options={this.state.categories.map((option) => {
                          return {
                            label: option.name,
                            value: option._id,
                          };
                        })}
                        onChange={(searchText) => {
                          console.log(searchText);
                          this.setState(
                            {
                              categoryValue: {
                                value: "",
                                label: searchText,
                              },
                            },
                            () => {
                              this.fetchCategories(searchText);
                            }
                          );
                        }}
                        onSelect={this.selectCat}
                      />
                      {/* <FormGroup>
                        <label> Category</label>
                        <br />
                        <select
                          style={{ height: "2.8rem" }}
                          className="form-control filters"
                          name="perPage"
                          value={this.state.categoryValue}
                          onChange={(e) => {
                            this.setState(
                              { categoryValue: e.target.value },
                              () => {
                                this.getProducts();
                                this.fetchSubCategories();
                              }
                            );
                          }}
                        >
                          <option selected="true" disabled value="">
                            {this.state.superCategoryValue.length > 0
                              ? "Now Select a Category"
                              : "Select Super Category First"}
                          </option>
                          {this.state.__categories.map((category, i) => (
                            <>
                             
                              <option key={i} value={category._id}>
                                {category.name}
                              </option>
                            </>
                          ))}
                        </select>
                      </FormGroup> */}
                    </div>

                    {/* subcategories */}

                    <div className="col-3">
                      <SelectOrganelle
                        label={"Sub Category"}
                        value={this.state.subCategoryValue}
                        options={this.state.sub_categories.map((option) => {
                          return {
                            label: option.name,
                            value: option._id,
                          };
                        })}
                        onChange={(searchText) => {
                          console.log(searchText);
                          this.setState(
                            {
                              subCategoryValue: {
                                value: "",
                                label: searchText,
                              },
                            },
                            () => {
                              this.fetchSubCategories(searchText);
                            }
                          );
                          // this.setState(
                          //   {
                          //     subcatSearch: searchText,
                          //   },
                          //   this.fetchSubCategories(searchText)
                          // );
                        }}
                        onSelect={this.selectSubCat}
                      />
                      {/* <FormGroup>
                        <label>Sub Category</label>
                        <br />
                        <select
                          style={{ height: "2.8rem" }}
                          className="form-control filters"
                          name="perPage"
                          value={this.state.subCategoryValue}
                          onChange={(e) => {
                            this.setState(
                              { subCategoryValue: e.target.value },
                              () => {
                                this.getProducts();
                              }
                            );
                          }}
                        >
                          <option selected="true" disabled value="">
                            Select Category First!
                          </option>
                          {this.state.__subCategories?.map((subCategory, i) => (
                            <>
                              <option key={i} value={subCategory._id}>
                                {subCategory.name}
                              </option>
                            </>
                          ))}
                        </select>
                      </FormGroup> */}
                    </div>

                    {/* Brand */}

                    <div className="col-3">
                      <SelectOrganelle
                        label={"Brand"}
                        onChange={(searchText) => {
                          this.setState(
                            {
                              brandValue: {
                                value: "",
                                label: searchText,
                              },
                            },
                            () => {
                              this.getBrands(searchText);
                            }
                          );
                          // this.setState({ brandSearch: searchText });
                          this.getBrands(searchText);
                        }}
                        value={this.state.brandValue}
                        options={this.state.brands.map((brand) => {
                          return {
                            label: brand.title,
                            value: brand._id,
                          };
                        })}
                        onSelect={this.selectBrand}
                      />
                      {/* <FormGroup>
                        <label>Brand</label>
                        <br />
                        <select
                          className="form-control filters"
                          name="brand"
                          required={false}
                          onChange={(e) => {
                            this.setState(
                              { brandValue: e.target.value },
                              () => {
                                this.getProducts();
                              }
                            );
                          }}
                        >
                          <option selected="true" disabled value="">
                            Select Brand
                          </option>
                          {this.state.brands.map((brand) => (
                            <option value={brand._id}>{brand.title}</option>
                          ))}
                        </select>
                      </FormGroup> */}
                    </div>
                  </div>

                  {/* <Button
                    style={{ marginLeft: "20px", marginTop: "0px" }}
                    className="btn-round"
                    color="primary"
                    onClick={() => {
                      this.handleDownload();
                    }}
                  >
                    Export To CSV
                  </Button> */}
                </CardHeader>
                <CardBody className="bg-black shadow-sm">
                  {/* ///// search and per page */}
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    {/* ////// search //////// */}
                    <div className="col-6 d-flex align-items-center input-wrapper">
                      <Input
                        style={{ maxWidth: 180, marginRight: 20 }}
                        placeholder="Name"
                        name="name"
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value }, () => {
                            this.getProducts();
                          });
                        }}
                        type="text"
                      />
                      {/* <span htmlFor="">
                          {" "}
                          <FontAwesomeIcon
                            icon={faSearch}
                            onClick={this.productSearch}
                          />
                        </span>
                        <input
                          type="text"
                          name="name"
                          value={this.state.name}
                          placeholder="Name"
                          className="input-tag"
                          onChange={(e) => {
                            this.setState(
                              {
                                name: e.target.value,
                              },
                              () => {
                                this.getProducts();
                              }
                            );
                          }}
                        /> */}

                      <Button
                        style={{ height: 45 }}
                        className="btn-round"
                        color="success"
                        type="submit"
                        onClick={() => {
                          this.state.name && this.productSearch();
                        }}
                      >
                        {this.state.searching ? "Working" : "Search"}
                      </Button>
                    </div>

                    {/* /////// per page ///// */}
                    <div className="col-6">
                      <div className="col-3">
                        <FormGroup>
                          <label>Per Page</label>
                          <br />
                          <select
                            style={{ height: "2.8rem" }}
                            className="form-control"
                            name="perPage"
                            value={this.state.perPage}
                            onChange={this.handlePage}
                          >
                            {[10, 25, 50].map((category, i) => (
                              <option key={i} value={category}>
                                {category}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {/*//////// main-row //////// */}
                  {this.state.data.map((v, i) => (
                    <div className="position-relative">
                      <table className="table responsive bg-black borderless main-table">
                        <table className="tablesorter table bg-black borderless">
                          <thead className="borderless">
                            <tr className="table-head-row  d-flex align-items-center">
                              <th className="col-1">{i + 1}</th>
                              <th className="col-1 text-center">
                                {v.lowest_variant?.image ? (
                                  <img
                                    src={
                                      typeof v.lowest_variant?.image == "string"
                                        ? Constants.imgUrl +
                                          v.lowest_variant?.image[1]
                                        : Constants.imgUrl +
                                          v.lowest_variant?.image[0]
                                    }
                                    width="80px"
                                    alt="img"
                                  />
                                ) : (
                                  <div>
                                    <span className="visible">
                                      No Image Available
                                    </span>
                                    <FontAwesomeIcon
                                      className="font-awesome-icons position-relative"
                                      icon={faSadTear}
                                    />
                                  </div>
                                )}{" "}
                              </th>

                              <th className="col-3">
                                {v.name?.length > 40
                                  ? v.name.substring(0, 40) + "..."
                                  : v.name}{" "}
                              </th>

                              <th className="col-2">
                                <select
                                  className="form-control"
                                  name="status"
                                  style={{ width: "11yaxty0px" }}
                                  value={v.status}
                                  onChange={(e) => this.handleStatus(e, v._id)}
                                >
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="INACTIVE">INACTIVE</option>
                                </select>
                              </th>

                              <th className="col-4">
                                <div id="wrapper">
                                  <header className="d-flex">
                                    {/* ////// more info */}
                                    <div
                                      class="iconDiv"
                                      tooltip="More Info"
                                      tabindex="0"
                                      onClick={() => {
                                        this.setState((prevState) => {
                                          console.log(prevState);
                                          this.setState({
                                            dropDown:
                                              prevState.productId === v._id
                                                ? false
                                                : true,
                                            productId:
                                              prevState.productId === v._id
                                                ? ""
                                                : v._id,
                                          });
                                        });
                                      }}
                                    >
                                      <div class="iconSVG mt-2">
                                        <FontAwesomeIcon
                                          className="font-awesome-icons"
                                          icon={faInfoCircle}
                                        />
                                      </div>
                                    </div>

                                    {/* ///// trending ////// */}
                                    <div
                                      class="iconDiv"
                                      tooltip="Trending"
                                      tabindex="0"
                                      onClick={() => {
                                        this.setState((prevState) => {
                                          console.log(prevState);
                                          this.setState({
                                            dropDown:
                                              prevState.productId === v._id
                                                ? false
                                                : true,
                                            productId:
                                              prevState.productId === v._id
                                                ? ""
                                                : v._id,
                                          });
                                        });
                                      }}
                                    >
                                      <div class="iconSVG">
                                        <FontAwesomeIcon
                                          icon={faArrowTrendUp}
                                          className="font-awesome-icons pt-1"
                                          onClick={() => {
                                            this.handleModal(
                                              v,
                                              "trending",
                                              "yes"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {/* invoice */}
                                    {/* <Link
                                      onClick={() => {
                                        let url =
                                          Constants.postUrls.downloadInvoice +
                                          allorders.orders[0].order_id;
                                        const timestamp = new Date().getTime();
                                        const downloadUrl = `${url}?timestamp=${timestamp}`;

                                        const newWindow = window.open(
                                          downloadUrl,
                                          "_blank"
                                        );
                                        newWindow.addEventListener(
                                          "load",
                                          () => {
                                            newWindow.setTimeout(() => {
                                              newWindow.close();
                                            }, 200); 
                                          }
                                        );
                                      }}
                                    >
                                      <div
                                        class="iconDiv"
                                        tooltip="Invoice"
                                        tabindex="0"
                                      >
                                        <div class="iconSVG">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="1"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </Link> */}
                                    <div class="spacer"></div>
                                    <div class="divider"></div>
                                    {/* ///// best sellers/////// */}
                                    {v.bestseller_status === "no" ? (
                                      <Link
                                        onClick={() => {
                                          this.handleBestseller(
                                            v,
                                            "bestsellers",
                                            "yes"
                                          );
                                        }}
                                      >
                                        <div
                                          class="iconDiv"
                                          tooltip="Best Seller"
                                          tabindex="0"
                                        >
                                          <div class="iconSVG">
                                            <FontAwesomeIcon
                                              className="font-awesome-icons pt-1"
                                              icon={faTrophy}
                                            />
                                          </div>
                                        </div>
                                      </Link>
                                    ) : (
                                      <Link
                                        onClick={() => {
                                          this.handleBestseller(
                                            v,
                                            "bestsellers",
                                            "yes"
                                          );
                                        }}
                                      >
                                        <div
                                          class="iconDiv"
                                          tooltip="Best Seller"
                                          tabindex="0"
                                        >
                                          <div class="iconSVG">
                                            <FontAwesomeIcon
                                              className="font-awesome-icons pt-1"
                                              color="red"
                                              icon={faTrophy}
                                            />
                                          </div>
                                        </div>
                                      </Link>
                                    )}

                                    {/* ///// ratings ////// */}
                                    <Link
                                      to={{
                                        pathname:
                                          "/admin/viewallratings/" + v._id,
                                        state: { data: v },
                                      }}
                                    >
                                      <div
                                        class="iconDiv"
                                        tooltip="Ratings"
                                        tabindex="0"
                                      >
                                        <div class="iconSVG">
                                          {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="1"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                          </svg> */}
                                          <FontAwesomeIcon
                                            icon={faStar}
                                            className="font-awesome-icons pt-1"
                                          />
                                        </div>
                                      </div>
                                    </Link>
                                  </header>
                                </div>
                              </th>
                              <th className="col-4">
                                {/* /// variant ///// */}
                                <Link
                                  to={{
                                    pathname: "/admin/variants/" + v._id,
                                    state: { data: v },
                                  }}
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    className="font-awesome-icons pr-2 fa-variant"
                                    icon={faPalette}
                                  />
                                </Link>
                                {/* //// update ///// */}
                                <Link
                                  to={{
                                    pathname: "/admin/updateproduct/" + v._id,
                                    state: { data: v },
                                  }}
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    className="font-awesome-icons pr-2 fa-update"
                                    icon={faPen}
                                  />
                                </Link>
                                {/* ///// delete ////// */}
                                <Link
                                  onClick={() => {
                                    this.handleModal(v, "delete");
                                  }}
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    className="font-awesome-icons pr-2 fa-trash"
                                    icon={faTrash}
                                  />
                                </Link>
                              </th>
                            </tr>
                          </thead>
                        </table>
                        {/* body */}
                        <Modal
                          visible={this.state.visible}
                          width="600"
                          height="100"
                          effect="fadeInUp"
                          onClickAway={() => this.setState({ visible: false })}
                        >
                          <Row>
                            <Col xs={12} sm={12} md={12}>
                              <Card>
                                {/* <CardHeader color="rose" icon>
                      <CardIcon color="danger">
                        <EditIcon />
                      </CardIcon>
                    <h4>Stacked Form</h4>
                    </CardHeader> */}
                                <CardBody>
                                  <div style={{ textAlign: "center" }}>
                                    <h3>Are You Sure You Want to Delete It?</h3>
                                    {this.state.notifyDelete ? (
                                      <div>
                                        <span style={{ color: "green" }}>
                                          Product Deleted Successfuly!
                                        </span>
                                      </div>
                                    ) : null}
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Col xs={12} sm={12} md={4}>
                                        <br />
                                        {/* <div style={{alignItems:"center",justifyContent:"center",flex:"right",marginLeft:"20px"}}> */}
                                        <Button
                                          className=""
                                          color="warning"
                                          onClick={() => {
                                            this.handleDelete(this.state.rowId);
                                          }}
                                        >
                                          {this.state.delLoading
                                            ? "Deleting..."
                                            : "Yes"}
                                        </Button>
                                        <Button
                                          className=""
                                          color="success"
                                          onClick={() => {
                                            this.setState({ visible: false });
                                          }}
                                        >
                                          No
                                        </Button>
                                        {/* </div> */}
                                      </Col>
                                    </Row>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Modal>
                        <Modal
                          visible={this.state.visibleTrending}
                          width="600"
                          height="100"
                          effect="fadeInUp"
                          onClickAway={() =>
                            this.setState({ visibleTrending: false })
                          }
                        >
                          <Row>
                            <Col xs={12} sm={12} md={12}>
                              <Card>
                                {/* <CardHeader color="rose" icon>
                        <CardIcon color="danger">
                          <EditIcon />
                        </CardIcon>
                      <h4>Stacked Form</h4>
                      </CardHeader> */}
                                <CardBody>
                                  <div>
                                    <h3 style={{ textAlign: "center" }}>
                                      Are You Sure You Want to{" "}
                                      {this.state.trendingStatus === "yes"
                                        ? "Trend"
                                        : "Untrend"}{" "}
                                      It?
                                    </h3>
                                    {this.state.notifyTrending ? (
                                      <div>
                                        <span style={{ color: "green" }}>
                                          {this.state.trendingStatus === "yes"
                                            ? "Added To Trending Successfuly!"
                                            : "Removed From Trending Successfuly!"}
                                        </span>
                                      </div>
                                    ) : null}
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Col xs={12} sm={12} md={4}>
                                        <br />
                                        <Button
                                          className=""
                                          color="success"
                                          onClick={() => {
                                            this.handleTrending(
                                              this.state.trendId
                                            );
                                          }}
                                        >
                                          {this.state.trending
                                            ? this.state.trendingStatus ===
                                              "yes"
                                              ? "Trending..."
                                              : "Untrnding..."
                                            : "Yes"}
                                        </Button>
                                        <Button
                                          className=""
                                          color="danger"
                                          onClick={() => {
                                            this.setState({
                                              visibleTrending: false,
                                            });
                                          }}
                                        >
                                          No
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Modal>
                        {/* addbest seller==== */}
                        <Modal
                          visible={this.state.visibleBestSellers}
                          width="600"
                          height="100"
                          effect="fadeInUp"
                          onClickAway={() =>
                            this.setState({ visibleBestSellers: false })
                          }
                        >
                          <Row>
                            <Col xs={12} sm={12} md={12}>
                              <Card>
                                <CardBody>
                                  <div>
                                    <h3 style={{ textAlign: "center" }}>
                                      Are You Sure You Want to{" "}
                                      {this.state.bestSellersStatus === "yes"
                                        ? "Add Best Seller"
                                        : "Remove Best Seller"}{" "}
                                      It?
                                    </h3>
                                    {this.state.notifyBestSellers ? (
                                      <div>
                                        <span style={{ color: "green" }}>
                                          {this.state.trendingStatus === "yes"
                                            ? "Added To Add Best Sller Successfuly!"
                                            : "Removed From Add Best Seller Successfuly!"}
                                        </span>
                                      </div>
                                    ) : null}
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Col xs={12} sm={12} md={4}>
                                        <br />
                                        <Button
                                          className=""
                                          color="success"
                                          onClick={() => {
                                            this.handleBestseller(
                                              this.state.bestSellerId
                                            );
                                          }}
                                        >
                                          {this.state.bestsellers
                                            ? this.state.bestSellersStatus ===
                                              "yes"
                                              ? "Best seller..."
                                              : "Un Best seller..."
                                            : "Yes"}
                                        </Button>
                                        <Button
                                          className=""
                                          color="danger"
                                          onClick={() => {
                                            this.setState({
                                              visibleBestSellers: false,
                                            });
                                          }}
                                        >
                                          No
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Modal>
                      </table>

                      {/*//////// dropdown //////// */}
                      <table className="table responsive bg-black borderless main-table borderless">
                        <table className="tablesorter table bg-black borderless">
                          <thead className="borderless">
                            <tr
                              className={`table-head-row  d-flex align-items-center check ${
                                this.state.productId &&
                                v._id === this.state.productId
                                  ? "show-drop-down-table-component"
                                  : "hide-drop-down-table-component"
                              }`}
                            >
                              <th>Super Category: {v.super_category?.title}</th>
                              <th className="">
                                Category: {v.category ? v.category.name : "NA"}
                              </th>
                              <th>MRP: SAR{v.mrp_price}</th>
                              <th>Selling Price: SAR{v.selling_price}</th>
                              <th className=""></th>
                              {/* <th className="">
                                {v.bestseller_status === "no" ? (
                                  <Button
                                    className="btn-round"
                                    color="success"
                                    type="submit"
                                    style={{
                                      marginLeft: "-33px",
                                      width: "65px",
                                      height: "45px",
                                    }}
                                    onClick={() => {
                                      this.handleBestseller(
                                        v,
                                        "bestsellers",
                                        "yes"
                                      );
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        emoji_events
                                      </span>
                                      <span className="mongo-title">
                                        Add Best Seller
                                      </span>
                                    </div>
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-round"
                                    color="warning"
                                    type="submit"
                                    onClick={() => {
                                      this.handleBestseller(
                                        v,
                                        "bestsellers",
                                        "no"
                                      );
                                    }}
                                    style={{
                                      marginLeft: "-33px",
                                      width: "65px",
                                      height: "45px",
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        emoji_events
                                      </span>
                                      <span className="mongo-title">
                                        Best Seller
                                      </span>
                                    </div>
                                  </Button>
                                )}
                              </th> */}
                              {/* <th className="">
                                {v.trending_status === "no" ? (
                                  <Button
                                    className="btn-round"
                                    color="success"
                                    type="submit"
                                    style={{
                                      marginLeft: "-33px",
                                      width: "65px",
                                      height: "45px",
                                    }}
                                    onClick={() => {
                                      this.handleModal(v, "trending", "yes");
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        trending_up
                                      </span>
                                      <span className="mongo-title">Trend</span>
                                    </div>
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-round"
                                    color="warning"
                                    type="submit"
                                    onClick={() => {
                                      this.handleModal(v, "trending", "no");
                                    }}
                                    style={{
                                      marginLeft: "-33px",
                                      width: "65px",
                                      height: "45px",
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        trending_up
                                      </span>
                                      <span className="mongo-title">
                                        Trending
                                      </span>
                                    </div>
                                  </Button>
                                )}{" "}
                              </th> */}
                              {/* <th>
                                <Link
                                  to={{
                                    pathname: "/admin/updateproduct/" + v._id,
                                    state: { data: v },
                                  }}
                                >
                                  <Button
                                    className="btn-round"
                                    color="info"
                                    type="submit"
                                    style={{
                                      marginLeft: "-33px",
                                      width: "65px",
                                      height: "45px",
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        Edit
                                      </span>
                                      <span className="mongo-title">
                                        Update
                                      </span>
                                    </div>
                                  </Button>
                                </Link>
                              </th> */}
                              {/* <th>
                                <Link
                                  to={{
                                    pathname: "/admin/variants/" + v._id,
                                    state: { data: v },
                                  }}
                                >
                                  <Button
                                    className="btn-round"
                                    color="info"
                                    type="submit"
                                    style={{
                                      marginLeft: "-33px",
                                      width: "65px",
                                      height: "45px",
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        variables
                                      </span>
                                      <span className="mongo-title">
                                        Variant
                                      </span>
                                    </div>
                                  </Button>
                                </Link>
                              </th> */}
                              {/* <th>
                                <Link
                                  to={{
                                    pathname: "/admin/viewallratings/" + v._id,
                                    state: { data: v },
                                  }}
                                >
                                  <Button
                                    className="btn-round"
                                    color="warning"
                                    type="submit"
                                    style={{
                                      marginLeft: "-33px",
                                      width: "65px",
                                      height: "45px",
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        star_rate
                                      </span>
                                      <span className="mongo-title">
                                        Rating
                                      </span>
                                    </div>
                                  </Button>
                                </Link>
                              </th> */}
                              {/* <th>
                                <Button
                                  className="btn-round"
                                  color="danger"
                                  type="submit"
                                  style={{
                                    marginLeft: "-33px",
                                    width: "65px",
                                    height: "45px",
                                  }}
                                  onClick={() => {
                                    this.handleModal(v, "delete");
                                  }}
                                >
                                  <div className="mongo">
                                    <span class="material-symbols-outlined">
                                      delete
                                    </span>
                                    <span className="mongo-title">Delete</span>
                                  </div>
                                </Button>
                              </th> */}
                            </tr>
                          </thead>
                        </table>
                      </table>
                    </div>
                  ))}

                  {this.state.data.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>

                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <PaginationItem
                              className={
                                // this.state.active === v ? "active" : ""
                                currentPage === v ? "active" : ""
                              }
                              key={i}
                            >
                              <PaginationLink
                                onClick={() => {
                                  this.currentPage(v);
                                }}
                              >
                                {v}
                              </PaginationLink>
                            </PaginationItem>
                          ))}

                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Products;
