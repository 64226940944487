import React, { Component } from "react";
import { useParams } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import { green } from "@material-ui/core/colors";
import CopyToClipboard from "react-copy-to-clipboard";
import { faCheck, faDownload } from "@fortawesome/free-solid-svg-icons";
import "../css/ToolTipStyle.css";
import BarsOrganelle from "components/Organelles/BarsOrganelle";

const filterButtons = [
  {
    label: "Order Placed",
    value: "order-placed",
  },
  { label: "Order Confirmed", value: "ORDER-CONFIRMED" },
  { label: "Shipped", value: "SHIPPED" },
  // { label: "PICKED UP", value: "PICKED UP" },
  { label: "Out for Delivery", value: "OUT FOR DELIVERY" },
  { label: "Order Cancelled", value: "CANCEL" },
  { label: "Delivered", value: "DELIVERED" },
];

const color_obj = {
  "order-placed": "btn-primary",
  "ORDER-CONFIRMED": " btn-info",
  SHIPPED: " btn-warning",
  "OUT FOR DELIVERY": " btn-success",
  DELIVERED: " btn-secondary",
  CANCEL: " btn-danger",
};

export default class DashBoard extends Component {
  mainDomain = "https://core.gifty60.com/";

  state = {
    notifyStatus: false,
    dashbaordValue: [],
    data: [],
    filteredOrders: [],
    logistics: [],
  };

  componentDidMount() {
    this.getDashboard();
    this.getOrders();
  }
  updateDeliveryStatus = (id, delivery_status) => {
    const payload = { delivery_status };
    axios
      .put(Constants.putUrls.updateDeliveryStatus + id, payload)
      .then((response) => {
        console.log(response);
        this.getOrders();
      });
  };
  getOrders() {
    axios
      .get(Constants.getUrls.orders + "?limit=5&is_dashboard=yes")
      .then((res) => {
        this.setState({
          data: res.data[0].paginatedResults,
          filteredOrders: res.data[0].paginatedResults,
        });
        console.log(res.data, "dashboard orders");
      });
  }

  getDashboard() {
    axios.get(Constants.getUrls.dashboard).then((resp) => {
      console.log(resp.data, "dashboard");
      this.setState({
        dashbaordValue: resp.data,
      });
    });
  }

  // fetchUserDetails() {
  //   const parameter = useParams();
  //   const parameterId = parameter.id;

  //   axios
  //     .get(`${mainDomain}auth/userDetails/:${parameterId}`)
  //     .then((response) => {
  //       console.log(response.data);
  //     });
  // }

  render() {
    const { dashbaordValue } = this.state;
    return (
      <>
        <div className="content overflow-hidden">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Order Status Changed Successfully!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          <Row className="mt-4">
            <Col>
              <Card className="my-4 mx-4 px-4 pt-4" color="light">
                <span
                  style={{
                    backgroundColor: "white",
                    fontSize: 72,
                    position: "absolute",
                    bottom: "61%",
                  }}
                  className="material-symbols-outlined"
                >
                  person
                </span>

                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="pt-4"
                >
                  <Col>
                    <h5>Total Categories</h5>
                  </Col>
                  <Col>
                    <h2 style={{ textAlign: "end", fontSize: "40px" }}>
                      {dashbaordValue ? dashbaordValue.categoriesCount : ""}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col>
              <Card className="my-4 mx-4 px-4 pt-4" color="light">
                <span
                  style={{
                    backgroundColor: "white",
                    fontSize: 72,
                    position: "absolute",
                    bottom: "61%",
                  }}
                  className="material-symbols-outlined"
                >
                  person
                </span>

                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="pt-4"
                >
                  <Col>
                    <h5>Total Products</h5>
                  </Col>
                  <Col>
                    <h2 style={{ textAlign: "end", fontSize: "40px" }}>
                      {dashbaordValue ? dashbaordValue.productCount : ""}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col>
              <Card className="my-4 mx-4 px-4 pt-4" color="light">
                <span
                  style={{
                    backgroundColor: "white",
                    fontSize: 72,
                    position: "absolute",
                    bottom: "61%",
                  }}
                  className="material-symbols-outlined"
                >
                  person
                </span>

                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="pt-4"
                >
                  <Col>
                    <h5>Total &nbsp; &nbsp; &nbsp; Users </h5>
                  </Col>
                  <Col>
                    <h2 style={{ textAlign: "end", fontSize: "40px" }}>
                      {dashbaordValue ? dashbaordValue.usersCount : " "}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col>
              <Card className="my-4 mx-4 px-4 pt-4" color="light">
                <span
                  style={{
                    backgroundColor: "white",
                    fontSize: 72,
                    position: "absolute",
                    bottom: "61%",
                  }}
                  className="material-symbols-outlined"
                >
                  person
                </span>

                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="pt-4"
                >
                  <Col>
                    <h5>Total SubCategory</h5>
                  </Col>
                  <Col>
                    <h2 style={{ textAlign: "end", fontSize: "40px" }}>
                      {dashbaordValue ? dashbaordValue.subCategoryCount : ""}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Orders</CardTitle>
                </CardHeader>

                <CardBody>
                  <div>
                    {this.state.filteredOrders.map((allorders, i) => (
                      <>
                        <div className="mb-2 text-primary ">
                          {/*////////// 1st Row ///////// */}
                          <div className="row position-relative border p-2 align-items-center justify-content-between">
                            {/* ////// Order ID Column ///////// */}
                            <div className="col-4 d-flex align-items-center">
                              <h5 className="text-muted mb-0 mr-2">
                                Order ID:{" "}
                              </h5>
                              <h4 className="mb-0 gradient-text-dashboard-Id gradient-text-dashboard-Id">
                                {allorders.orders[0].order_id}
                              </h4>
                            </div>

                            {/* ////// Date Column ///////// */}
                            <div className="col-4 d-flex align-items-center">
                              <h5 className="text-muted mb-0 mr-2">
                                Order Date:
                              </h5>
                              <h5 className="mb-0">
                                {Constants.days[
                                  new Date(
                                    allorders.orders[0].order_date
                                  ).getDay()
                                ] +
                                  " " +
                                  new Date(
                                    allorders.orders[0].order_date
                                  ).getDate() +
                                  " " +
                                  Constants.month[
                                    new Date(
                                      allorders.orders[0].order_date
                                    ).getMonth()
                                  ] +
                                  " " +
                                  new Date(
                                    allorders.orders[0].order_date
                                  ).getFullYear() +
                                  ", " +
                                  new Date(
                                    allorders.orders[0].order_date
                                  ).getHours() +
                                  ":" +
                                  new Date(
                                    allorders.orders[0].order_date
                                  ).getMinutes()}
                              </h5>
                            </div>

                            {/* ////// Action Icons Column ///////// */}
                            <div className="col-4 position-reltaive d-flex justify-content-end">
                              <Link
                                className="position-relative mx-3 "
                                to={
                                  "/admin/orderDetails/" +
                                  allorders.orders[0].order_id
                                }
                              >
                                <div style={{ top: -10 }} className="mongo">
                                  <span
                                    style={{ fontSize: 20 }}
                                    class="material-symbols-outlined "
                                  >
                                    Launch
                                  </span>
                                  <span className="mongo-title">
                                    Order Details
                                  </span>
                                </div>
                              </Link>
                              <Link
                                className="position-relative mx-3"
                                to={
                                  "/admin/userDetails/" +
                                  allorders.orders[0].user._id
                                }
                              >
                                <div style={{ top: -10 }} className="mongo">
                                  <span
                                    style={{ fontSize: 20 }}
                                    class="material-symbols-outlined"
                                  >
                                    Person
                                  </span>
                                  <span className="mongo-title">
                                    User Details
                                  </span>
                                </div>
                              </Link>
                              <Link
                                style={{ top: -10 }}
                                onClick={() => {
                                  let url =
                                    Constants.postUrls.downloadInvoice +
                                    allorders.orders[0].order_id;
                                  // window.open(url, '_blank');
                                  const timestamp = new Date().getTime();
                                  const downloadUrl = `${url}?timestamp=${timestamp}`;
                                  console.log(allorders.orders[0]._id);
                                  // window.location.href = url ;
                                  const newWindow = window.open(
                                    downloadUrl,
                                    "_blank"
                                  );
                                  newWindow.addEventListener("load", () => {
                                    newWindow.setTimeout(() => {
                                      newWindow.close();
                                    }, 200); // Delay the closing of the window (adjust the timeout as needed)
                                  });
                                }}
                                className="position-relative mx-3"
                                // to={"/admin/userDetails/" + orders.user._id}
                              >
                                <div className="mongo">
                                  <span
                                    style={{ fontSize: 20 }}
                                    class="material-symbols-outlined"
                                  >
                                    Download
                                  </span>
                                  <span className="mongo-title">Invoice</span>
                                </div>
                              </Link>
                              {/* {allorders.orders[0].delivery_status ===
                                "CANCEL" &&
                              allorders.orders[0].payment_mode === "online" &&
                              allorders.orders[0].payment_status ===
                                "success" ? (
                                <Link className="position-relative">
                                  <div
                                    onClick={() => {
                                      console.log("click");
                                      this.setState({
                                        refundModalDisplay: true,
                                        refund_modal_amount:
                                          allorders.orders[0].selling_price,
                                      });
                                    }}
                                    className="mongo"
                                  >
                                    <span
                                      style={{ fontSize: 20 }}
                                      class="material-symbols-outlined"
                                    >
                                      Undo
                                    </span>
                                    <span className="mongo-title">
                                      Amount Refund
                                    </span>
                                  </div>
                                </Link>
                              ) : (
                                ""
                              )} */}

                              {/* <Button
                              className="btn-round"
                              color="primary"
                              style={{
                                marginLeft: "10px",
                                // width: "65px",
                                height: "45px",
                              }}
                              
                            > */}

                              {/* <div className="mongo">
                                <span className="material-symbols-outlined">
                                  download
                                </span>
                              </div> */}
                              {/* </Button> */}
                            </div>
                          </div>

                          {/*////////// 2nd Row ///////// */}

                          <div
                            style={{ fontSize: 12, color: "black" }}
                            className="row    border p-2 "
                          >
                            <div className="col-4">
                              {allorders.orders.map((orders, index) => (
                                <div className="d-flex">
                                  <div className="col-1 py-2">{index + 1}</div>
                                  {/* ///////orders/////// */}

                                  <div className="col-12 py-2">
                                    <div>
                                      <span>Name: </span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {orders.product
                                          ? orders.product.name
                                          : null}
                                      </span>
                                    </div>
                                    <div>
                                      <span>Size: </span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {orders.variant
                                          ? orders.variant.size
                                            ? orders.variant.size[0].size
                                            : "NA"
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div>
                                      <span>Color: </span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {orders.variant
                                          ? orders.variant.color
                                            ? orders.variant.color[0].color
                                            : "NA"
                                          : "NA"}
                                      </span>
                                    </div>
                                    <div>
                                      <span>Quantity:</span>{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {orders.quantity}
                                      </span>
                                    </div>
                                    <div>
                                      <span>MRP: </span>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "green",
                                        }}
                                      >
                                        SAR{orders.mrp_price}
                                      </span>
                                    </div>
                                    <div>
                                      <span>Selling Price: </span>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "green",
                                        }}
                                      >
                                        SAR{orders.selling_price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="col-2 mt-2">
                              <span>Payment Status: </span>
                              <span style={{ fontWeight: "bolder" }}>
                                {allorders.orders[0].payment_status ===
                                "pending" ? (
                                  <span style={{ color: "orange" }}>
                                    {allorders.orders[0].payment_status.toUpperCase()}
                                  </span>
                                ) : allorders.orders[0].payment_status ===
                                  "success" ? (
                                  <span style={{ color: "green" }}>
                                    {allorders.orders[0].payment_status.toUpperCase()}
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    {allorders.orders[0].payment_status.toUpperCase()}
                                  </span>
                                )}
                              </span>
                              <div>
                                <span>Delivery Charges: </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "green",
                                  }}
                                >
                                  SAR{allorders.orders[0].delivery_amount}
                                </span>
                              </div>
                              <div>
                                <span>Order Total: </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "green",
                                  }}
                                >
                                  SAR{allorders.orders[0].total}
                                </span>
                              </div>
                              <div>
                                <span>Payment Method: </span>
                                <span style={{ fontWeight: "bold" }}>
                                  {allorders.orders[0].payment_mode}
                                </span>
                              </div>

                              <div>
                                <span>Wrapping:</span>{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {allorders.orders[0].gift_wrap_status || "NA"}
                                </span>
                              </div>
                            </div>{" "}
                            {/* ///////other details////// */}
                            <div
                              style={{ fontSize: 12 }}
                              className="col-3 mt-2"
                            >
                              <div className="d-flex ">
                                <CopyToClipboard
                                  text={
                                    allorders.orders[0].address?.first_name +
                                    " " +
                                    allorders.orders[0].address?.last_name +
                                    " " +
                                    allorders.orders[0].address?.mobile_no +
                                    " " +
                                    allorders.orders[0].address?.address_line1 +
                                    " " +
                                    allorders.orders[0].address?.address_line2 +
                                    " " +
                                    allorders.orders[0].address?.pincode
                                  }
                                  onCopy={() => {
                                    this.setState({
                                      copied: true,
                                      selectedOrder: allorders.orders[0]._id,
                                    });

                                    setTimeout(() => {
                                      this.setState({ copied: false });
                                    }, 3000);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      this.state.copied &&
                                      this.state.selectedOrder ===
                                        allorders.orders[0]._id
                                        ? faCheck
                                        : faCopy
                                    }
                                  />
                                </CopyToClipboard>
                                <div
                                  className=" fw-bolder text-uppercase"
                                  style={{
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {allorders.orders[0].address?.first_name}{" "}
                                  {allorders.orders[0].address?.last_name}
                                  {<br></br>}
                                  {allorders.orders[0].address?.mobile_no}{" "}
                                  {<br></br>}
                                  {
                                    allorders.orders[0].address?.address_line1
                                  }, {<br></br>}
                                  {
                                    allorders.orders[0].address?.address_line2
                                  }{" "}
                                  {allorders.orders[0].address?.city}
                                  <br />
                                  {allorders.orders[0].address?.pincode}
                                </div>
                              </div>
                            </div>
                            <div className="col-3 mt-2">
                              <span className="mr-3">Delivery Status: </span>
                              <button
                                className={`form-control w-50 mt-2 ${
                                  color_obj[allorders.orders[0].delivery_status]
                                }`}
                              >
                                {allorders.orders[0].delivery_status}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  {this.state.filteredOrders.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : this.state.empty ? null : (
                    <div style={{ marginTop: "20px" }}>
                      {/* <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div> */}

                      {/* <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <>
                              {console.log(v)}
                              <PaginationItem
                                className={
                                  this.state.active === v ? "active" : ""
                                }
                                key={i}
                              >
                                <PaginationLink
                                  onClick={() => {
                                    this.currentPage(v);
                                  }}
                                >
                                  {v}
                                </PaginationLink>
                              </PaginationItem>
                            </>
                          ))}
                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div> */}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <div className="col-12">
              <BarsOrganelle />
            </div>
          </Row>

          <Modal
            visible={this.state.visible}
            width="700"
            height="330"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    {this.state.details ? (
                      <Row>
                        <Col md="6">
                          {this.state.image ? (
                            <img
                              width="100%"
                              alt=""
                              src={Constants.imgUrl + this.state.image}
                            ></img>
                          ) : (
                            "Image Not Available"
                          )}
                        </Col>
                        <Col md="6">
                          {this.state.description ? (
                            <div>{this.state.description}</div>
                          ) : (
                            "Description Not Available"
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <form>
                        <Row>
                          <Col md="6">
                            <label>Logistics</label>
                            <br />
                            <select
                              style={{ padding: "11px", width: "100%" }}
                              name="logic"
                              // value={this.state.logic}
                              onChange={this.handleInput}
                            >
                              <select value="">Select</select>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                            <FormGroup>
                              <label>Tracking No</label>
                              <Input
                                placeholder="Tracking No"
                                name="trackingNo"
                                onChange={this.handleInput}
                                required={true}
                                type="text"
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={4}>
                            {this.state.notification ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Shipping Details Added Successfuly!
                                </span>
                              </div>
                            ) : null}
                            <Button
                              className=""
                              color="warning"
                              onClick={this.addShipping}
                            >
                              {this.state.loading ? "Adding..." : "Add"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={this.state.orderStatusModal}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Are You Sure You Want to Cancel this Order? <br></br>
                        This Process is{" "}
                        <span className="text-warning"> Irreversible!</span>
                      </h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Order Status Changed Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Col xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.updateDeliveryStatus(
                                this.state.id,
                                this.state.delivery_status
                              );
                              this.setState({ orderStatusModal: false });
                            }}
                          >
                            {this.state.delLoading
                              ? "Changing Status..."
                              : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="success"
                            onClick={() => {
                              this.setState({ orderStatusModal: false });
                            }}
                          >
                            No
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={this.state.refundModalDisplay}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Are You Sure You Want to Refund SAR{" "}
                        {this.state.refund_modal_amount} ?
                      </h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Payment Refunded Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Col xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.updateDeliveryStatus(
                                this.state.id,
                                this.state.delivery_status
                              );
                              this.setState({ refundModalDisplay: false });
                            }}
                          >
                            {this.state.delLoading
                              ? "Changing Status..."
                              : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="success"
                            onClick={() => {
                              this.setState({ refundModalDisplay: false });
                            }}
                          >
                            No
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}
